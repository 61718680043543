import React, { useEffect, useState, useCallback, useMemo } from "react";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  ModalBody,
  Modal,
  CardFooter,
  CardTitle,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import transactionsService from "../../services/api/transaction-membership-service";
import Projects from "../projects/Projects";

const RECORDS_PER_PAGE = 10;

const UserTransaction = ({ userId, projectId }) => {
  // State management
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Memoize form state to prevent unnecessary rerenders
  const formState = useMemo(() => ({
    user_id: userId,
    project_id: projectId,
  }), [userId, projectId]);

  // Fetch transactions with proper error handling
  const fetchTransactions = useCallback(async () => {
    if (!projectId || !userId) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await transactionsService.getTransactions(
        formState,
        activePage,
        RECORDS_PER_PAGE
      );
      
      if (response.status && response.data) {
        setTransactions(response.data);
        
        const totalCount = await transactionsService.getTotalTransactions(formState);
        if (totalCount.status) {
          setTotalTransactions(totalCount.data.count);
        }
      } else {
        setError("Error: Failed to get transactions");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError("Failed to load transactions. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [formState, activePage, projectId, userId]);

  // Effect to fetch data when dependencies change
  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  // Handle modal actions
  const handleOpenModal = useCallback((transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  }, []);

  // Get transaction status text
  const getStatusText = useCallback((status) => {
    switch (status) {
      case 0: return "Initiated";
      case 1: return "Order Created";
      case -1: return "Order could not be created";
      case 2: return "Success";
      case -2: return "Failed";
      default: return "Unknown";
    }
  }, []);

  // Handle page change
  const handlePageChange = useCallback((pageNumber) => {
    setActivePage(pageNumber);
  }, []);

  // Render transaction rows with memoization
  const renderTransactionRows = useCallback(() => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan="9" className="text-center py-4">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </td>
        </tr>
      );
    }

    if (error) {
      return (
        <tr>
          <td colSpan="9" className="text-center text-danger py-4">
            {error}
          </td>
        </tr>
      );
    }

    if (!transactions.length) {
      return (
        <tr>
          <td colSpan="9" className="text-center py-4">
            <CardTitle tag="h5">No Records Found</CardTitle>
          </td>
        </tr>
      );
    }

    return transactions.map((transaction, index) => {
      const rowNumber = RECORDS_PER_PAGE * (activePage - 1) + index + 1;
      const statusClass = transaction.status === 2 
        ? "text-success" 
        : transaction.status <0 
          ? "text-danger" 
          : "";
     
      return (
        <tr key={transaction.id || index}>
          <td className="text-left">{rowNumber}</td>
          <td className="text-left">{transaction?.user_id?.email || transaction?.user_id?.phone_number || "" }</td>
          <td className="text-left">₹{((transaction?.amount || 0) / 100).toFixed(2)}</td>
          <td className="text-left">
            {(transaction?.subscription_id?.pg_subscription_id) || ""}
          </td>
          <td className="text-left">{transaction.pg_txn_id || ""}</td>
          <td className="text-left">
            <Moment format="DD MMM YYYY hh:mm:ss a">
              {transaction.createdat}
            </Moment>
          </td>
          <td className="text-left">
            {transaction.type === "1" ? "One time" : "Recurring"}
          </td>
          <td className={`text-left`}>
            {getStatusText(transaction.status)}
          </td>
          <td>
            <Button
              color="info"
              size="sm"
              onClick={() => handleOpenModal(transaction)}
            >
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </td>
        </tr>
      );
    });
  }, [transactions, isLoading, error, activePage, handleOpenModal, getStatusText]);

  if (!projectId && !userId) {
    return <Projects navigate="membership/transaction" />;
  }

  return (
    <div className="content">
      <Container>
        <Card className="card-signup">
          <CardHeader className="bg-white p-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <CardTitle tag="h4" className="mb-0">
                Transactions Dashboard
              </CardTitle>
            </div>
            <hr />
            {totalTransactions > 0 && (
                  <Row>
                    <Col md="6">
                      <div style={{ float: "right", margin: "5px" }}>
                        <b>
                          {(activePage - 1) * RECORDS_PER_PAGE + 1} -{" "}
                          {Math.min(
                            activePage * RECORDS_PER_PAGE,
                            totalTransactions
                          )}{" "}
                          of {totalTransactions}
                        </b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "left" }}>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={RECORDS_PER_PAGE}
                          totalItemsCount={totalTransactions}
                          pageRangeDisplayed={3}
                          onChange={setActivePage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
            {/* Table Section */}
            <div className="table-responsive">
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th className="text-left">#</th>
                    <th className="text-left">User Name or Phone No.</th>
                    <th className="text-left">Transaction Amount(INR)</th>
                    <th className="text-left">Membership ID</th>
                    <th className="text-left">Transaction Id</th>
                    <th className="text-left">Transaction Date</th>
                    <th className="text-left">Type</th>
                    <th className="text-center">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderTransactionRows()}</tbody>
              </Table>
            </div>
            
          
          </CardHeader>
          
          <CardFooter>
         
          </CardFooter>
        </Card>
      </Container>
      
      {/* Transaction Details Modal */}
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        size="md"
      >
        <ModalBody className="p-3 position-relative">
          {/* Close Button */}
          <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{
              right: "12px",
              top: "8px",
              cursor: "pointer",
              zIndex: 1,
              backgroundColor: "red",
              borderRadius: "50%",
              width: "25px",
              height: "25px",
            }}
            onClick={() => setModalOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </div>

          {/* Transaction Details Section */}
          <div className="mb-3">
            <h6 className="mb-2">Transaction Details</h6>
            <div className="border rounded">
              <Row className="g-0">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Transaction ID
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Order ID</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.order_id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    PG Transaction ID
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.pg_txn_id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    PG Transaction Message
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.pg_txn_message || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Amount</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    ₹{(selectedTransaction?.amount / 100).toFixed(2) || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Status</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {getStatusText(selectedTransaction?.status)}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Transaction Date
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.createdat ? (
                      <Moment format="DD MMM YYYY hh:mm:ss a">
                        {selectedTransaction?.createdat}
                      </Moment>
                    ) : ""}
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <h6 className="mb-2">Membership Details</h6>
            <div className="border rounded">
              <Row className="g-0">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Membership ID
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id
                      ?.pg_subscription_id || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Paid Count</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.paid_count ||
                      ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Status</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.status || ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Type</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.total_count === 1
                      ? "One Time"
                      : selectedTransaction?.subscription_id?.total_count
                      ? "Recurring"
                      : ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">Share Url</div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.share_url ||
                      ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Membership Start Date
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.start_at
                      ? moment
                          .unix(
                            selectedTransaction?.subscription_id?.start_at
                          )
                          .format("DD MMM YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
              <Row className="g-0 border-top">
                <Col xs="5" className="p-2 bg-light">
                  <div className="fw-semibold text-dark">
                    Membership End Date
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="fw-semibold">
                    {selectedTransaction?.subscription_id?.end_at
                      ? moment
                          .unix(
                            selectedTransaction?.subscription_id?.end_at
                          )
                          .format("DD MMM YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserTransaction;