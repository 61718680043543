import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Switch from "react-bootstrap-switch";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import projectsService from "../../services/api/projects-service";

const WebstoriesConfigForm = (props) => {
  const projectId = props.projectId
  const [error, setError] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [googleApiKeyError, setGoogleApiKeyError] = useState({ show: false, msg: "", type: "" })

  const [formdetails, setFormDetails] = useState({})
  useEffect(() => {
    console.log("useEffect")  
    setFormDetails(props.formdetails)
  }, [projectId, props.formdetails]);

  const handleSwitchChange = (fieldname, fieldvalue) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: fieldvalue,
    });
  }
  const handleInputChange = (event, fieldname) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: event.target.value,
    });
    setGoogleApiKeyError({ show: false, msg: "", type: "has-success" })
  }

  const settingsFormValidations = () => {
    // if (formdetails.google_translate_api_key !== "" && formdetails.google_translate_api_key.trim() === "") {
    //   setGoogleApiKeyError({ show: true, msg: "Please enter correct key", type: "has-danger" })
    //   return false;
    // }
    return true;
  }
  const validateForm = async () => {
    if (settingsFormValidations()) {
      setShowLoader(true);
      setShowSaveButton(false);

      let saveProjectSettings = await projectsService.saveProjectSettings(formdetails);
      if (!saveProjectSettings.status && saveProjectSettings.error.errorCode === 403) {
        console.log(saveProjectSettings)
      }
      else if (saveProjectSettings.status) {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true);
          setAlert({ show: true, type: 'success', message: 'Project settings were saved successfully' });
        }, 1000);

      } else {
        setTimeout(() => {
          setShowLoader(false)
          setShowSaveButton(true)
          setAlert({ show: true, type: 'danger', message: 'Project settings could not be saved, Please try again.' });
        }, 1000);
      }

      setTimeout(() => {
        setAlert({ show: false, type: '', message: '' });
      }, 3000);
    }
  }

  const handleSubmit = function (e) { validateForm(); e.preventDefault() }
  return (
    <div className="content">
      <Row>
        <Col className="ml-auto mr-auto" md="12">
          <Card className="card-signup text-center">
            <CardHeader>
              <CardTitle tag="h4">Project Webstories Settings</CardTitle>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontol" action="" id="projectSetting" method="POST" onSubmit={handleSubmit}>
                <Row>
                  <Label sm="2">Enable Slug AI Translation</Label>
                  <Col sm="7">
                    <FormGroup>
                      <Switch name="enable_slug_ai_translate"
                        offColor="success"
                        offText={<i className="nc-icon nc-simple-remove" />}
                        onColor="success"
                        onText={<i className="nc-icon nc-check-2" />}
                        value={formdetails.enable_slug_ai_translate}
                        onChange={(value, enabled) => handleSwitchChange('enable_slug_ai_translate', enabled)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {showSaveButton ? <Button
                  className="btn-round"
                  color="info"
                  type="button"
                  onClick={validateForm}
                >
                  Save
                </Button> : ''}
                {alert.show ? <Alert color={alert.type}>
                  {alert.message}
                </Alert> : ''}

                {showLoader ? <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner> : ''}

              </Form>
            </CardBody>

          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WebstoriesConfigForm;
