import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Button,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faTimes,
  faUsers,
  faFileExcel,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Projects from "../projects/Projects";
import projectUsersService from "../../services/api/project-users-service";
import projectsService from "../../services/api/projects-service";
import moment from "moment";
import { Link } from "react-router-dom";

const UserProfileDashboard = (props) => {
  const projectId = new URLSearchParams(props.location.search).get("projectid");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [tokenDetails, setTokenDetails] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [exportFields, setExportFields] = useState(["name", "createdAt"]);
  const [exportError, setExportError] = useState("");
  const [userNameArray, setUserNames] = useState([]);

  const [formState, setFormState] = useState({
    email: "",
    first_name: "",
    last_name: "",
    start_date: "",
    end_date: "",
  });

  const [projectDetails, setProjectDetails] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
  });

  const availableExportFields = [
    { label: "Full Name", key: "name" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email Address", key: "email" },
    { label: "Contact Number", key: "phone" },
    { label: "Created At", key: "createdAt" },
  ];

  const filterKey = useMemo(
    () =>
      JSON.stringify({
        email: formState.email,
        first_name: formState.first_name,
        last_name: formState.last_name,
        start_date: formState.start_date,
        end_date: formState.end_date,
        page: pagination.currentPage,
       
      }),
    [formState, pagination.currentPage]
  );

  const firstRecord = (pagination.currentPage - 1) * pagination.pageSize + 1;
  const lastRecord = Math.min(
    pagination.currentPage * pagination.pageSize,
    pagination.totalItems
  );

  useEffect(() => {
    (async () => {
      try {
        const result = await projectsService.getProjectDetails(projectId);
        if (result && result.status === true) {
          setProjectDetails(result.data);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    })();
  }, [projectId]);

  const getUsers = async () => {
    if (!validateformState()) return;

    setIsLoading(true);
    setError(null);

    try {
      const result = await projectUsersService.fetchUsers({
        project_id: projectId,
        first_name: formState.first_name || "",
        last_name: formState.last_name || "",
        email: formState.email || "",
        start_date: formState.start_date || "",
        end_date: formState.end_date || "",
        page: pagination.currentPage,
        page_size: pagination.pageSize,
      });

      console.log(result);
      if (result && result.status === false) {
        setUsers([]);
        setError("No users found");
      } else {
        setUsers(result.data.users || []);
        setPagination((prev) => ({
          ...prev,
          totalItems: result.data.count || 0,
        }));
      }
    } catch (err) {
      setError("Error fetching users");
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
  }, [filterKey]);

  const getUserToken = async (user_id) => {
    try {
      setIsLoadingToken(true);
      setTokenError(null);
      const result = await projectUsersService.fetchUserTokenDetails(user_id);
      console.log("result", result);
      if (result && result.status && result.data) {
        console.log("result", result.data);
        setTokenDetails(result.data);
        setModalOpen(true);
      } else {
        console.log(error);
        setError(result.error || "Failed to fetch token details");
      }
    } catch (err) {
      setTokenError("Error fetching user token");
    } finally {
      setIsLoadingToken(false);
    }
  };
  const closeModal = () => {
    setModalOpen(false);
    setTokenDetails(null);
    setError(null);
  };

  const toggleExportModal = () => {
    setExportModalOpen(!exportModalOpen);
    setExportError("");
  };

  const handleFieldSelection = (fieldKey) => {
    setExportFields((prevFields) =>
      prevFields.includes(fieldKey)
        ? prevFields.filter((key) => key !== fieldKey)
        : [...prevFields, fieldKey]
    );
  };
  const handleSelectAll = () => {
    setExportFields((prevFields) =>
      prevFields.length === availableExportFields.length
        ? []
        : availableExportFields.map((field) => field.key)
    );
  };

  const handleExport = async () => {
    if (users.length === 0) {
      setExportError("No users found");
      return;
    }
    if (exportFields.length === 0) {
      setExportError("Please select at least one field to export.");
      return;
    }
    if (!validateformState()) return;

    let startDate = formState.start_date
      ? moment(formState.start_date)
      : moment().subtract(2, "months");
    let endDate = formState.end_date ? moment(formState.end_date) : moment();
    try {
      const response = await projectUsersService.exportUsers({
        fields: exportFields,
        project_id: projectId,
        first_name: formState.first_name,
        last_name: formState.last_name,
        email: formState.email,
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName = `Users-${projectDetails.name}-${startDate.format(
        "YYYYMMDD"
      )}-to-${endDate.format("YYYYMMDD")}.xlsx`;
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();

      toggleExportModal();
    } catch (error) {
      console.error("Error exporting user details:", error);
      setExportError("Failed to export user details. Please try again later.");
    }
  };

  const fetchProjectUsers = useCallback(async () => {
    if (!projectId) return;
    try {
      const response = await projectUsersService.fetchUsers({
        project_id: projectId,
        page: 1,
        page_size: pagination.totalItems,
      });
      if (response?.status && response?.data?.users) {
        const users = response.data.users.map((user) => ({
          value: user.email,
          label: user.email ? `${user.name} (${user.email})` : `${user.name} ${user.phone_number}`,
          email: user.email,
        }));
        console.log(response.data)
        setUserNames(users);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [projectId]);

  // Effects

  useEffect(() => {
    fetchProjectUsers();
  }, [fetchProjectUsers]);

  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleReset = () => {
    setFormState({
      email: "",
      first_name: "",
      last_name: "",
      start_date: null,
      end_date: null,
    });

    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setExportError("");
    setTokenError(null);
    setValidationErrors({});
  };

  const validateformState = () => {
    const errors = {};

    if (formState.start_date && formState.end_date) {
      const startDate = new Date(formState.start_date);
      const endDate = new Date(formState.end_date);

      if (startDate > endDate) {
        errors.end_date = "End date must be after start date";
        setValidationErrors(errors);
        setExportError("End date must be after start date.");
        return false;
      }

      const monthDiff =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());
      if (monthDiff > 2) {
        errors.end_date = "Date range must be between less than 2 months.";
        setExportError("Date range must be between less than 2 months.");
        setValidationErrors(errors);
        return false;
      }
    }
    setValidationErrors({});
    return true;
  };

  const handleInfoButtonClick = (user) => {
    setSelectedUser(user);
    getUserToken(user.user_id);
  };
  if (!projectId) {
    return <Projects navigate="dashboard/user-profiles" />;
  } else {
    return (
      <div className="content mt-10">
        <Container fluid className="py-4">
          <Card className="shadow-lg mb-4">
            <CardHeader className="bg-white border-bottom-0 p-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <CardTitle tag="h4" className="mb-0 d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="me-3 text-primary"
                    style={{ fontSize: "1.5rem" }}
                  />
                  Users Profile
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ms-3 text-muted"
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="This dashboard displays a list of users who have logged in to this project using Single Sign-On (SSO)."
                  />
                </CardTitle>

                <div className="d-flex-column align-items-start justify-content-start">
                  <div className="text-end">
                    <div className="text-muted" style={{ fontSize: "0.9rem" }}>
                      <div>
                        Project:<strong>{projectDetails.name}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Form id="transactionformState" onSubmit={handleSearch}>
                <Row>
                  <Col md="3">
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={formState.first_name}
                      onChange={(e) =>
                        handleFormChange("first_name", e.target.value)
                      }
                      
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={formState.last_name}
                      onChange={(e) =>
                        handleFormChange("last_name", e.target.value)
                      }
                     
                    />
                  </Col>

                 
                  <Col md="3">
                    <ReactDatetime
                      value={formState.start_date}
                      onChange={(date) =>
                        handleFormChange("start_date", date._d)
                      }
                      inputProps={{
                        className: "form-control",
                        placeholder: "From Date",
                      }}
                     
                    />
                  </Col>

                  <Col md="3">
                    <ReactDatetime
                      value={formState.end_date}
                      onChange={(date) => handleFormChange("end_date", date._d)}
                      inputProps={{
                        className: "form-control",
                        placeholder: "To Date",
                      }}
                      
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                
 
                <Col md="3">
                    <Select
                      isClearable
                      placeholder="Select User"
                      options={userNameArray}
                      value={
                        userNameArray.find(
                          (option) => option.value === formState.email
                        ) || null
                      }
                      onChange={(option) =>
                        handleFormChange("email", option?.value || "")
                      }
                   
                    />
                  </Col>
                
                
                  <Col md="9">
                    <Button
                      className="btn-round mr-2"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>

                    <Button
                      color="success"
                      onClick={toggleExportModal}
                      className="btn-round mr-2"
                    >
                      <FontAwesomeIcon icon={faFileExcel} className="me-2" />
                      Excel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardHeader>
          </Card>

          <Card className="shadow-lg">
            <CardBody>
              {pagination.totalItems > 0 ? (
                <Row>
                  <Col md="5" className="pt-2">
                    <div style={{ float: "right" }}>
                      <b>
                        {firstRecord} - {lastRecord} of {pagination.totalItems}
                      </b>
                    </div>
                  </Col>
                  <Col md="6">
                    <div style={{ float: "left" }}>
                      <Pagination
                        activePage={pagination.currentPage}
                        itemsCountPerPage={pagination.pageSize}
                        totalItemsCount={pagination.totalItems}
                        pageRangeDisplayed={3}
                        onChange={(pageNumber) =>
                          setPagination((prev) => ({
                            ...prev,
                            currentPage: pageNumber,
                          }))
                        }
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {isLoading ? (
                <div className="text-center py-5">
                  <Spinner color="primary" />
                  <p className="mt-3">Loading Users...</p>
                </div>
              ) : error ? (
                <div className="alert alert-danger text-center">{error}</div>
              ) : users.length === 0 ? (
                <div className="alert alert-info text-center">
                  No users found
                </div>
              ) : (
                <Table responsive hover striped>
                  <thead className="thead-light text-center">
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email or Phone Number</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td>{firstRecord + index}</td>
                        <td>
                          <Link
                            to={`/dashboard/user-detailed-view?userid=${user.user_id}&projectid=${projectId}`}
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            {`${user.first_name ?? ""} ${user.last_name ?? ""}`}
                          </Link>
                        </td>

                        <td>{user.email || user?.phone_number || ""}</td>
                        <td>
                          <div>
                            {new Date(user.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </div>
                          <div>
                            {new Date(user.createdAt).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </div>
                        </td>
                        <td>
                          <Link
                            to={`/dashboard/user-detailed-view?userid=${user.user_id}&projectid=${projectId}`}
                          >
                            <Button
                              color="info"
                              size="sm"
                           
                            >
                              {/* {isLoadingToken ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faInfo} />} */}
                              <FontAwesomeIcon icon={faInfo} />
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          <Modal
            isOpen={modalOpen}
            toggle={() => setModalOpen(false)}
            size="md"
          >
            <ModalBody className="p-3 position-relative">
              {/* Close Button */}
              <div
                className="position-absolute d-flex align-items-center justify-content-center"
                style={{
                  right: "12px",
                  top: "8px",
                  cursor: "pointer",
                  zIndex: 1,
                  backgroundColor: "red", // Red background
                  borderRadius: "50%", // Circle shape
                  width: "25px", // Width of the icon container
                  height: "25px", // Height of the icon container
                }}
                onClick={() => setModalOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} className="text-white" />{" "}
                {/* White cross */}
              </div>

              {/* Transaction Details Section */}
              <div className="mb-3">
                <h6 className="mb-2">Transaction Details</h6>
                <div className="border rounded">
                  <Row className="g-0">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">
                        Transaction ID
                      </div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">9acf456-1</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">Amount</div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">Rs 500</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">Status</div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">Success</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">
                        Transaction Date
                      </div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">2024-12-15</div>
                    </Col>
                  </Row>
                </div>
              </div>

            
              <div>
                <h6 className="mb-2">Subscription Details</h6>
                <div className="border rounded">
                  <Row className="g-0">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">
                        Subscription ID
                      </div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">1001</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">
                        Subscription Name
                      </div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">Premium Plan</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">
                        Subscription Period
                      </div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">Yearly</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">Start Date</div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">2024-01-21</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">End Date</div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">2024-12-31</div>
                    </Col>
                  </Row>
                  <Row className="g-0 border-top">
                    <Col xs="5" className="p-2 bg-light">
                      <div className="fw-semibold text-dark">Benefits</div>
                    </Col>
                    <Col className="p-2">
                      <div className="fw-semibold">
                        Access to all premium features. 24/7 customer support,
                        no ads.
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={exportModalOpen} centered>
            <ModalHeader className="bg-light text-center">
              Export User Data
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label className="fw-bold mb-2">
                  Select Fields to Add in Excel File
                </Label>
                <div
                  className="border rounded p-2 overflow-auto"
                  style={{ maxHeight: "200px" }}
                >
                  <div className="fs mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="selectAll"
                      onChange={handleSelectAll}
                      checked={
                        exportFields.length === availableExportFields.length
                      }
                    />
                    <label
                      className="form-check-label ms-2"
                      htmlFor="selectAll"
                    >
                      Select All
                    </label>
                  </div>

                  {availableExportFields.map((field) => (
                    <div className="fs" key={field.key}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={field.key}
                        onChange={() => handleFieldSelection(field.key)}
                        checked={exportFields.includes(field.key)}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor={field.key}
                      >
                        {field.label}
                      </label>
                    </div>
                  ))}
                </div>
              </FormGroup>
              {exportError && (
                <div className="text-danger text-center mt-3 small">
                  {exportError}
                </div>
              )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="primary" onClick={handleExport}>
                Export
              </Button>
              <Button color="secondary" onClick={toggleExportModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
};

export default UserProfileDashboard;
