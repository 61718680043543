import React, { useEffect, useState, useCallback, useMemo } from "react";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import projectService from "../../services/api/projects-service";
import moment from "moment";

import GenAIUsageChart from './GenAIUsageChart';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import GenaiFeatureUsageService from "../../services/api/gen-ai-feature-usage-service.js";

const RECORDS_PER_PAGE = 10;

const INITIAL_FORM_STATE = {
  api_type: [],
  credit_consumed: "",
  from_date: moment().utc().subtract(7, "days").startOf("day").toDate(),
  to_date: moment().utc().endOf("day").toDate(),
  project_id: [],
  subscription_id: "",
};

const GenaiFeatureUsage = () => {
  const [genAIFeatureUsage, setGenAIFeatureUsage] = useState([]);
  const [formState, setFormState] = useState(INITIAL_FORM_STATE);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [apiType, setApiType] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);

  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleFilterReset = useCallback(() => {
    setFormState(INITIAL_FORM_STATE);
    setActivePage(1);
  }, []);

  // This function fetches Gen AI feature usage based on filters
  const fetchGenAIUsage = useCallback(async () => {
    setIsLoading(true);
    try {
      console.log("Fetching data with filters:", formState);
      const result = await GenaiFeatureUsageService.getGenAIFeatureUsage(
        formState,
        activePage,
        RECORDS_PER_PAGE
      );
      console.log(result)
      if (result?.status) {
        setGenAIFeatureUsage(result.data.result);
      }
    } catch (error) {
      console.error("Error fetching GenAI usage:", error);
    } finally {
      setIsLoading(false);
    }
  }, [formState, activePage]);

  // This function fetches the API types and project details only once
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      // Fetch API types
      const apiResult = await GenaiFeatureUsageService.getGenAIApiType();
      if (apiResult?.status) {
        setApiType(apiResult.data);
      }

      // Fetch projects
      const projectsData = await projectService.getAllProjects({status : 1});
      if (projectsData.status && projectsData.data) {
        setProjects(
          projectsData.data.map((proj) => ({
            value: proj.id,
            label: proj.name,
          }))
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch data once on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Fetch GenAI usage whenever formState changes
  useEffect(() => {
    fetchGenAIUsage();
  }, [formState, activePage, fetchGenAIUsage]);

  const handleFilterSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setActivePage(1); // Reset to the first page when filters change
      fetchGenAIUsage();
    },
    [fetchGenAIUsage]
  );

  const apiTypeOptions = useMemo(
    () => apiType.map((type) => ({ value: type, label: type })),
    [apiType]
  );

  return (
    <div className="content">
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <CardTitle tag="h4">Gen AI Feature Usage</CardTitle>
                </div>

                <Form onSubmit={handleFilterSubmit}>
                  <Row>
                    <Col md="3">
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select API Type"
                        options={apiTypeOptions}
                        value={apiTypeOptions.filter((option) =>
                          formState.api_type.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "api_type",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                      />
                    </Col>
                    <Col md="3">
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Project"
                        options={projects}
                        value={projects.filter((option) =>
                          formState.project_id.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "project_id",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                      />
                    </Col>
                    <Col md="3">
                      <ReactDatetime
                        value={moment(formState.from_date)}
                        onChange={(date) =>
                          handleFormChange("from_date", moment(date).toDate())
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "From Date",
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <ReactDatetime
                        value={moment(formState.to_date)}
                        onChange={(date) =>
                          handleFormChange("to_date", moment(date).toDate())
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "To Date",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="6">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Search"}
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={handleFilterReset}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>

              <CardBody>
  <GenAIUsageChart data = {genAIFeatureUsage}/>
</CardBody>

            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GenaiFeatureUsage;
