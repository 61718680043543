import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupText,
  Tooltip,
  FormText,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { useHistory, useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "react-bootstrap-switch";
import widgetsService from "../../services/api/widget-builder-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TemplateFormBuilder from "./TemplateFormBuilder";
import {
  faCheckCircle,
  faClipboard,
  faClipboardList,
  faCopy,
  faPaste,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const EditCricketWidget = () => {
  const history = new useHistory();
  const { id } = useParams();

  console.log("id", id);
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    widget_slug: "",
    publisher: "",
    publisher_slug: "",
    template: "",
    layout: "carousel",
    title: "",
    custom_css: "",
    domains: [{ domain: "", isValid: true }],
    tournaments: [],
    matches: [],
    widget_data: {
      bg_color: "#6c757d",
      is_clickable: false,
      url_type: "",
      full_url: "",
      prefix_url: "",
      auto_refresh: false,
      refresh_widget_time: "",
      template_data: {},
      widgets_count: {
        scheduled: 4,
        completed: 2,
    }
    },
    widget_code: "",
    id: "",
    tournament_type: "featured_tournament",
    association: "",
  });

    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const [selectedTournament, setSelectedTournament] = useState(null); // 🆕 Tournament selection state
    const [publishers, setPublishers] = useState([
        { value: "Publisher One", label: "Publisher One" },
        { value: "Publisher Two", label: "Publisher Two" }
    ]);
    const [disabled, setDisabled] = useState(false);
    const [usernamestate, setNameState] = useState("");
    const [nameerror, setNameError] = useState("");
    const [publisherState, setPublisherState] = useState("");
    const [publisherError, setPublisherError] = useState("");
    const [templateState, setTemplateState] = useState("");
    const [templateError, setTemplateError] = useState("");
    const [layoutState, setLayoutState] = useState("");
    const [layoutError, setLayoutError] = useState("");
    const [bgColorState, setBgColorState] = useState("");
    const [alert, setAlert] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [jsEmbedd, setJsEmbedd] = useState("");
    const [copied, setCopied] = useState(false);
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [matchOptions, setMatchOptions] = useState([]);
    const [featuredMatches, setFeaturedMatches] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [publisherOptions, setPublisherOptions] = useState([]);
    const [matchError, setMatchError] = useState({ msg: "", state: "" });
    const [tournamentError, setTournamentError] = useState({ msg: "", state: "" });
    const [refreshTimeError, setRefreshTimeError] = useState({ msg: "", state: "" });
    const [associationOptions, setAssociationOptions] = useState([]);
    const [associationTournamentsOptions, setAssociationTournamentsOptions] = useState([]);
    const [associationError, setAssociationError] = useState({ msg: "", state: "" });
    const [widgetConfig, setWidgetConfig] = useState({});
  const [formData, setFormData] = useState({});
  const [template, setTemplate] = useState({});
  const [errors, setErrors] = useState({});
  const [initialData, setInitialData] = useState({});


    const templates = [
        { label: "Tournament", value: "tournament" },
        { label: "Match", value: "match" },
        { label: "Sidebar-Tournament-Points Table", value: "sidebar-tournament-points-table" },
        { label: "Sidebar-Match-Team Innings", value: "sidebar-match-team-innings" },
        { label: "Sidebar-Tournament-Top Wicket Takers", value: "sidebar-tournament-top-wicket-takers" },
        { label: "Sidebar-Tournament-Top Run Scorer", value: "sidebar-tournament-top-run-scorer" },
        { label: "Sidebar-Tournament-Card", value: "sidebar-tournament-card" },
        { label: "Single Match Card", value: "single-match-card" },
        { label: "Tournament All Matches Carousel", value: "tournament-all-matches-carousel" },
        { label: "Match & Tournament Detail Carousel", value: "match-tournament-detail-carousel" },
        { label: "Match Detail Carousel", value: "match-detail-carousel" },
        { label: "Tournament Latest Match Carousel", value: "tournament-latest-match-carousel" },
        { label: "Full Match Detail", value: "full-match-detail" },
        { label: "Tournament Latest Match Full Detail", value: "tournament-latest-match-full-detail" },
    ];

    const tournamentTemplates = [
        "tournament",
        "sidebar-tournament-points-table",
        "sidebar-tournament-top-wicket-takers",
        "sidebar-tournament-top-run-scorer",
        "sidebar-tournament-card",
        "tournament-all-matches-carousel",
        "match-tournament-detail-carousel",
        "tournament-latest-match-carousel",
        "tournament-latest-match-full-detail"
    ];

    const matchTemplates = [
        "match",
        "sidebar-match-team-innings",
        "single-match-card",
        "tournament-all-matches-carousel",
        "match-tournament-detail-carousel",
        "match-detail-carousel",
        "full-match-detail",
    ];

    const optionalMatchTemplates = [
      "tournament-latest-match-carousel",
      "tournament-latest-match-full-detail"
  ];

  useEffect(() => {
    if (id) {
      widgetsService
        .getDetails(id)
        .then((res) => {
          if (res) {
            setFormDetails((prev) => ({
              ...prev,
              name: res.data.widget_name,
              widget_slug: res.data.widget_slug,
              publisher: res.data.client_name,
              publisher_slug: res.data.client_slug,
              template: res.data.template,
              layout: res.data.layout || "carousel",
              tournaments: res.data.tournaments || [],
              matches: res.data.matches || [],
              widget_data: {
                bg_color: res.data.data?.bg_color || "#6c757d",
                is_clickable: res.data?.data?.is_clickable ?? false,
                prefix_url: res.data?.data?.prefix_url ?? "",
                full_url: res.data?.data?.full_url ?? "",
                url_type: res.data?.data?.url_type ?? "",
                refresh_widget_time: res.data?.data?.refresh_widget_time ?? "",
                auto_refresh: res.data?.data?.auto_refresh ?? false,
                widgets_count: res.data?.data?.widgets_count
              },
              widget_code: res.data.widget_code,
              id: res.data.id,
              title: res.data.title,
              domains:
                res.data.domain && res.data.domain.length > 0
                  ? res.data.domain
                  : prev.domains,
              custom_css: res.data.custom_css || "",
              tournament_type:
                res.data?.tournament_type || "featured_tournament",
              association: res.data?.association,
            }));

            setInitialData(res.data.data?.template_data || {});
          }
        })
        .catch((err) => {
          console.error("Error fetching widget details:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, tournamentOptions]);


  useEffect(() => {
    if (formDetails.template !== "" && widgetConfig?.templates?.items) {
      const templateConfig = widgetConfig.templates.items[formDetails.template];

      if (templateConfig) {
        setTemplate(templateConfig);

        if (templateConfig.items) {

          const newFormData = { ...templateConfig.items };


          Object.keys(newFormData).forEach((key) => {
            newFormData[key] = newFormData[key].default || '';
          });


          Object.keys(initialData).forEach((key) => {

            if (templateConfig.items[key]) {
              newFormData[key] = initialData[key];
            }
          });


          const filteredFormData = Object.keys(templateConfig.items)
            .reduce((acc, key) => {
              acc[key] = newFormData[key];
              return acc;
            }, {});


          const isDataChanged =
            JSON.stringify(filteredFormData) !== JSON.stringify(formData);

          if (isDataChanged) {
            setFormData(filteredFormData);
          }
        }
      } else {
        setTemplate({});
      }
    }
  }, [
    formDetails.template,
    widgetConfig,
    initialData
  ]);



  useEffect(() => {
    setFormDetails((prev) => ({
      ...prev,
      widget_data: {
        ...prev.widget_data, // Preserve other widget_data properties
        template_data: formData, // Update only template_data
      },
    }));
  }, [formData]);
  
  const layouts = [
    { label: "Carousel", value: "carousel" },
    { label: "Listing", value: "listing" },
  ];

  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNameError("");
    setNameState("");

    if (name === "name") {
      setFormDetails({
        ...formDetails,
        name: value,
        widget_slug: generateSlug(value),
      });
    } else if (name === "publisher") {
      setPublisherError("");
      setPublisherState("");
      setFormDetails({
        ...formDetails,
        [name]: value,
        publisher_slug: generateSlug(value),
      });
    } else if (name === "refresh_widget_time") {
      const trimmedValue = value.trim();
      // Check if the value is a number
      if (!isNaN(trimmedValue)) {
        setRefreshTimeError({ state: "", msg: "" });
        setFormDetails({
          ...formDetails,
          widget_data: {
            ...formDetails.widget_data,
            refresh_widget_time: trimmedValue, // Store only if it's a number
          },
        });
      }
    }
    else if (name === "scheduled_widget" || name === "completed_widget") {
      // Ensure it's a valid number
      const widgetValue = value.trim();
      if (!isNaN(widgetValue)) {
        setFormDetails({
          ...formDetails,
          widget_data: {
            ...formDetails.widget_data,
            widgets_count: {
              ...formDetails.widget_data.widgets_count,
              [name === "scheduled_widget" ? "scheduled" : "completed"]: widgetValue,
            },
          },
        });
      }
    }
    else {
      setFormDetails({ ...formDetails, [name]: value });
    }
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const formattedPublisherOptions = publisherOptions.map((publisher) => ({
    label: publisher.client_name || publisher.label,
    value: publisher.client_slug || publisher.value,
  }));

  const handlePublisherChange = (newValue) => {
    const trimmedValue = newValue?.value.trim() || "";

    setPublisherError("");
    setPublisherState("");
    setSelectedPublisher(newValue);
    setFormDetails({
      ...formDetails,
      publisher: trimmedValue,
      publisher_slug: trimmedValue ? generateSlug(trimmedValue) : "",
    });
  };

  const handleCreatePublisher = (inputValue) => {
    const trimmedValue = inputValue.trim();

    const newPublisher = {
      label: trimmedValue,
      value: trimmedValue,
    };

    // Update both options list and selected publisher
    setPublisherOptions([...publisherOptions, newPublisher]);
    setSelectedPublisher(newPublisher);

    // Update form details
    setFormDetails({
      ...formDetails,
      publisher: trimmedValue,
      publisher_slug: generateSlug(trimmedValue),
    });
  };

  const handleTemplateChange = (selectedOption) => {
    setTemplateError("");
    setTemplateState("");
    setFormDetails((prev) => ({
      ...prev,
      template: selectedOption.value,
      matches: selectedOption.value === "match" ? prev.matches : [],
    }));
  };

  const handleTournamentChange = (selectedOption) => {
    // 🆕 Tournament handler
    setSelectedTournament(selectedOption);
    setFormDetails({
      ...formDetails,
      tournament: selectedOption ? selectedOption.value : "",
    });
  };

  const validateName = () => {
    if (formDetails.name.trim() === "") {
      setNameState("has-danger");
      setNameError("Widget Name is required");
      return false;
    }
    setNameState("has-success");
    return true;
  };

  const validatePublisher = () => {
    if (!formDetails.publisher) {
      setPublisherState("has-danger");
      setPublisherError("Publisher Name is required");
      return false;
    }
    setPublisherState("has-success");
    return true;
  };

  const validateTemplate = () => {
    if (!formDetails.template) {
      setTemplateState("has-danger");
      setTemplateError("Template is required");
      return false;
    }
    setTemplateState("has-success");
    return true;
  };

  const validateLayout = () => {
    if (!formDetails.layout) {
      setLayoutState("has-danger");
      setLayoutError("Layout is required");
      return false;
    }
    setLayoutState("has-success");
    return true;
  };

  const validateBgColor = () => {
    if (formDetails.layout === "listing" && !formDetails.widget_data.bg_color) {
      setBgColorState("has-danger");
      return false;
    }
    setBgColorState("has-success");
    return true;
  };

  const showSuccessAlert = (message) => {
    setAlert(
      <SweetAlert success title="Success" onConfirm={() => setAlert(null)}>
        {message}
      </SweetAlert>
    );
  };

  const showErrorAlert = (message) => {
    setAlert(
      <SweetAlert danger title="Error" onConfirm={() => setAlert(null)}>
        {message}
      </SweetAlert>
    );
  };

  const validateAssociation = () => {
    if (
      formDetails.tournament_type === "association" &&
      !formDetails.association
    ) {
      // toast.error("Please select association");
      setAssociationError({
        msg: "Association is required",
        state: "has-danger",
      });
      return false;
    }
    return true;
  };

  const validateRefreshTime = () => {
    if (
      formDetails.widget_data.auto_refresh &&
      !formDetails.widget_data.refresh_widget_time
    ) {
      // toast.error("Please select association");
      setRefreshTimeError({
        msg: "Refresh Time is required",
        state: "has-danger",
      });
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;

    let userNameValidation = validateName();
    let publisherNameValidation = validatePublisher();
    let templateValidation = validateTemplate();
    let layoutValidation = validateLayout();
    let validatebgColor = validateBgColor();
    let associationValidation = validateAssociation();
    let refreshWidgetValidation = validateRefreshTime();
    let domainValidation = formDetails.domains.every(
      (domain) => domain.isValid && domain.domain !== ""
    );

    if (!domainValidation) {
      toast.error("Invalid domain format");
      return false;
    }

    if (
      matchTemplates.includes(formDetails.template) &&
      (!formDetails.matches || formDetails.matches.length === 0)
    ) {
      toast.error("Please select at least one match");
      setMatchError({
        msg: "Please select at least one match",
        state: "has-danger",
      });
      return false;
    }

    if (
      formDetails.template === "tournament" &&
      (!formDetails.tournaments || formDetails.tournaments.length === 0)
    ) {
      toast.error("Please select at least one tournament");
      setTournamentError({
        msg: "Please select at least one tournament",
        state: "has-danger",
      });
      return false;
    }

    if (
      userNameValidation &&
      publisherNameValidation &&
      templateValidation &&
      layoutValidation &&
      validatebgColor &&
      domainValidation &&
      associationValidation &&
      refreshWidgetValidation
    ) {
      return isValid;
    }

    toast.error("Please fill all required fields");
    isValid = false;
    return isValid;
  };

  const validateConfigForm = () => {
    const newErrors = {};
    if (Object.keys(template).length <= 0) return newErrors
    Object.entries(template.items).forEach(([fieldKey, fieldConfig]) => {
      const value = formData[fieldKey];

      console.log(fieldKey + " " + value);
      if (fieldConfig.required && (!value || value === "")) {
        console.log(value);
        newErrors[fieldKey] = `${fieldConfig.label} is required`;
      }

      if (fieldConfig.type === "url" && value) {
        const urlPattern =
          /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        if (!urlPattern.test(value)) {
          newErrors[fieldKey] = "Invalid URL format";
        }
      }

      if (fieldConfig.type === "hex_color" && value) {
        const hexPattern = /^#([0-9A-F]{3}){1,2}$/i;
        if (!hexPattern.test(value)) {
          newErrors[fieldKey] = "Invalid hex color format";
        }
      }
    });

    return newErrors;
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(jsEmbedd)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copy status after 2s
      })
      .catch((err) => console.error("Failed to copy!", err));
  };

  const handleCreate = async () => {
    if (validateForm()) {
      console.log("validated");
      setShowLoader(true);
      const validationErrors = validateConfigForm();

      if (Object.keys(validationErrors).length > 0) {
        toast.error("Please fix all the errors in config form");
        setErrors(validationErrors);
        return;
      }

      let widgetsData = await widgetsService.createWidget(formDetails);
      console.log("From backend:- ", widgetsData);

      if (widgetsData?.status && widgetsData?.data?.js_embed) {
        setJsEmbedd(widgetsData?.data?.js_embed);
        // setTimeout(() => {
        setShowLoader(false);
        toast.success(
          `${id ? "Widget updated Successfully" : "Widget Created Successfully"
          }`
        );
        // }, 1000);
        return;
      }

      toast.error("Failed to edit widget");
    }
  };

  const isValidDomain = (domain) => {
    const domainRegex =
      /^(\*\.)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const handleDomainChange = (index, value) => {
    const updatedDomains = [...formDetails.domains];
    updatedDomains[index].domain = value;
    updatedDomains[index].isValid = isValidDomain(value);
    setFormDetails({ ...formDetails, domains: updatedDomains });
  };

  const handleAddDomain = () => {
    const lastDomain = formDetails.domains[formDetails.domains.length - 1];
    if (!lastDomain.isValid || lastDomain.domain === "") {
      const updatedDomains = [...formDetails.domains];
      updatedDomains[formDetails.domains.length - 1].isValid = false;
      setFormDetails({ ...formDetails, domains: updatedDomains });
      toast.error("Invalid domain format");
      return;
    }
    setFormDetails({
      ...formDetails,
      domains: [...formDetails.domains, { domain: "", isValid: true }],
    });
  };

  const handleRemoveDomain = (index) => {
    const updatedDomains = [...formDetails.domains];
    if (updatedDomains.length > 1) {
      updatedDomains.splice(index, 1);
      setFormDetails({ ...formDetails, domains: updatedDomains });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [res1, res2, res3] = await Promise.all([
          widgetsService.getTournaments(),
          widgetsService.getFeaturedMatches(),
          widgetsService.getConfigSchema(),
        ]);

        console.log("These is tournaments:- ", res3);
        console.log(
          "These is are featrured Matches:- ",
          res2.data.data.matches
        );

        setTournamentOptions(res1.data.data);
        setMatchOptions(res2.data.data.matches);
        setFeaturedMatches(res2.data.data.matches);
        setWidgetConfig(res3.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        toast.error("Error fetching tournaments or matches!");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const matchData = await Promise.all(
          formDetails.tournaments.map(async (tournamentId) => {
            const response = await widgetsService.getMatches(tournamentId);
            return response.status && response.data ? response.data.data : [];
          })
        );

        // Flatten the accumulated match data and set it to state
        setMatchOptions(matchData.flat());
        // console.log("Accumulated Matches:", matchData.flat());
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };

        if ((matchTemplates.includes(formDetails.template) || optionalMatchTemplates.includes(formDetails.template)) && formDetails.tournaments.length > 0) {
            fetchMatches();
        }
    }, [formDetails.tournaments, formDetails.template]);

  console.log(jsEmbedd);

  useEffect(() => {
    const fetchClientNames = async () => {
      try {
        const response = await widgetsService.getAllClientNames();
        if (response.status && response.data) {
          console.log("All Client Names:", response.data);
          setPublisherOptions(response.data);
        }
      } catch (error) {
        console.error("Error fetching Client Names:", error);
        toast.error("Error fetching Client Names");
      }
    };

    fetchClientNames();
  }, [jsEmbedd]);

  useEffect(() => {
    if (formDetails.publisher) {
      const existingPublisher = formattedPublisherOptions.find(
        (option) => option.value === formDetails.publisher_slug
      );

      const selectedValue = existingPublisher || {
        label: formDetails.publisher,
        value: formDetails.publisher_slug,
      };

      // Only update if the selected value has actually changed
      if (
        !selectedPublisher ||
        selectedPublisher.value !== selectedValue.value
      ) {
        setSelectedPublisher(selectedValue);
      }
    }
  }, [
    formDetails.publisher,
    formDetails.publisher_slug,
    formattedPublisherOptions,
  ]);

  useEffect(() => {
    const fetchAssociations = async () => {
      try {
        const response = await widgetsService.getAssociations();
        if (response.data.status && response.data.data) {
          setAssociationOptions(response.data.data.associations);
        }
      } catch (error) {
        console.error("Error fetching associations:", error);
        toast.error("Error fetching associations");
      }
    };

    if (formDetails.tournament_type === "association") {
      fetchAssociations();
    }
  }, [formDetails.tournament_type]);

  useEffect(() => {
    const fetchAssociationTournaments = async () => {
      try {
        const associationKey = formDetails.association;
        const response = await widgetsService.getAssociationTournaments(
          associationKey
        );
        if (response.data.status && response.data) {
          console.log("Associations Tournaments:", response.data?.data);
          setAssociationTournamentsOptions(response.data.data);
        } else if (!response.data.status) {
          setAssociationTournamentsOptions([]);
        }
      } catch (error) {
        console.error("Error fetching association tournaments:", error);
        toast.error("Error fetching association tournaments");
      }
    };

    if (formDetails.association) {
      fetchAssociationTournaments();
    }
  }, [formDetails.association]);

  return (
    <div
      className="content d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Container
        style={{ width: formDetails.template !== "" ? "60%" : "100%" }}
      >
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader className="container">
                <CardTitle tag="h4">Edit Cricket Widget</CardTitle>
              </CardHeader>
              <Form className="mt-3">
                <Row className="justify-content-center">
                  <Label for="name" sm="2">
                    Name <span class="text-danger"> * </span>
                  </Label>
                  <Col sm="7">
                    <FormGroup className={usernamestate}>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={formDetails.name}
                        onChange={handleInputChange}
                        placeholder="Enter Widget Name"
                        autoComplete="off"
                      />
                      {usernamestate === "has-danger" ? (
                        <label className="error">{nameerror}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Label for="publisher" sm="2">
                    Publisher <span className="text-danger">*</span>
                  </Label>
                  <Col sm="7">
                    <FormGroup className={publisherState}>
                      <CreatableSelect
                        isClearable
                        options={formattedPublisherOptions}
                        value={selectedPublisher}
                        onChange={handlePublisherChange}
                        onCreateOption={handleCreatePublisher}
                        placeholder="Select or Create Publisher"
                      />

                      {/* <Input
                                                type="text"
                                                name="publisher"
                                                id="name"
                                                value={formDetails.publisher}
                                                onChange={handleInputChange}
                                                placeholder="Enter publisher Name"
                                                autoComplete="off"
                                            /> */}
                      {formDetails.publisher_slug && (
                        <small
                          className="text-muted d-block mt-1"
                          style={{ textAlign: "left" }}
                        >
                          Slug: {formDetails.publisher_slug}
                        </small>
                      )}
                      {publisherState === "has-danger" ? (
                        <label className="error">{publisherError}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Label for="title" sm="2">
                    Title
                  </Label>
                  <Col sm="7">
                    <FormGroup>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        value={formDetails.title}
                        onChange={handleInputChange}
                        placeholder="Enter Title"
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Label for="template" sm="2">
                    Template<span class="text-danger"> * </span>
                  </Label>
                  <Col sm="7">
                    <FormGroup className={templateState}>
                      <Select
                        options={templates}
                        value={
                          templates.find(
                            (template) =>
                              template.value === formDetails.template
                          ) || null
                        }

                        onChange={(selected) => {
                          setTemplateError("");
                          setTemplateState("");
                          setMatchError({ msg: "", state: "" });
                          setTournamentError({ msg: "", state: "" });
                          setFormDetails({
                            ...formDetails,
                            template: selected ? selected.value : "",
                          });
                          setErrors({});
                        }}
                        placeholder="Select Template"
                      />
                      {templateState === "has-danger" ? (
                        <label className="error">{templateError}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center align-items-center">
                  <Label sm="2">Tournament Type</Label>
                  <Col sm="7">
                    <FormGroup className="d-flex gap-4">
                      <Label check className="d-flex align-items-center gap-1">
                        <Input
                          type="radio"
                          name="tournament_type"
                          value="featured_tournament"
                          checked={
                            formDetails.tournament_type ===
                            "featured_tournament"
                          }
                          onChange={() => {
                            setFormDetails((prev) => ({
                              ...prev,
                              tournament_type: "featured_tournament",
                              association: "", // Reset association
                            }));
                            setAssociationTournamentsOptions([]); // Clear association tournaments
                          }}
                        />
                        Featured Tournament
                      </Label>
                      <Label check className="d-flex align-items-center gap-1">
                        <Input
                          type="radio"
                          name="tournament_type"
                          value="association"
                          checked={
                            formDetails.tournament_type === "association"
                          }
                          onChange={(e) =>
                            setFormDetails((prev) => ({
                              ...prev,
                              tournament_type: "association",
                            }))
                          }
                        />
                        Association Tournament
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                {formDetails.tournament_type === "association" && (
                  <Row className="justify-content-center">
                    <Label for="association" sm="2">
                      Association{" "}
                      {formDetails.tournament_type === "association" && (
                        <span className="text-danger">*</span>
                      )}
                    </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Select
                          options={associationOptions.map((item) => ({
                            label: `${item.name} (${item.code})`,
                            value: item.key,
                          }))}
                          value={
                            associationOptions.find(
                              (item) => item.key === formDetails.association
                            )
                              ? {
                                label: associationOptions.find(
                                  (item) =>
                                    item.key === formDetails.association
                                ).name,
                                value: formDetails.association,
                              }
                              : null
                          }
                          onChange={(selected) => {
                            setAssociationError({ msg: "", state: "" });
                            setFormDetails({
                              ...formDetails,
                              association: selected ? selected.value : "", // Save the selected key in `association`
                            });
                          }}
                          placeholder="Select Association"
                        />
                        {associationError.state === "has-danger" ? (
                          <label className="text-danger">
                            {associationError.msg}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Label for="tournaments" sm="2">
                    Tournaments{" "}
                    {tournamentTemplates.includes(formDetails.template) && (
                      <span className="text-danger">*</span>
                    )}
                  </Label>
                  <Col sm="7">
                    <FormGroup>
                      <Select
                        isMulti
                        options={(formDetails.association
                          ? associationTournamentsOptions
                          : tournamentOptions
                        ).map((item) => ({
                          label: `${item.name} (${item.formats[0].replace(
                            /^./,
                            (char) => char.toUpperCase()
                          )}) (${new Date(
                            item.start_date * 1000
                          ).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })})`,
                          value: item.slug,
                        }))}
                        value={(formDetails.association
                          ? associationTournamentsOptions
                          : tournamentOptions
                        )
                          .filter((item) =>
                            formDetails.tournaments.includes(item.slug)
                          )
                          .map((item) => ({
                            label: `${item.name} (${item.formats[0].replace(
                              /^./,
                              (char) => char.toUpperCase()
                            )}) (${new Date(
                              item.start_date * 1000
                            ).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                            })})`,
                            value: item.slug,
                          }))}
                        onChange={(selected) => {
                          setTournamentError({ msg: "", state: "" });
                          setFormDetails({
                            ...formDetails,
                            tournaments: selected
                              ? selected.map((option) => option.value)
                              : [], // ✅ Handle null case
                          });
                        }}
                        placeholder={
                          (formDetails.association
                            ? associationTournamentsOptions
                            : tournamentOptions
                          ).length === 0
                            ? "No Tournaments Available"
                            : "Select Tournaments"
                        }
                      // isDisabled={(formDetails.association ? associationTournamentsOptions : tournamentOptions).length === 0}
                      />

                      {tournamentError.state === "has-danger" ? (
                        <label className="text-danger">
                          {tournamentError.msg}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                                {/* Matches select (only visible if layout === "match") */}
                                {(matchTemplates.includes(formDetails.template) || optionalMatchTemplates.includes(formDetails.template)) && (
                                    <Row className="justify-content-center">
                                        <Label for="matches" sm="2">Matches {matchTemplates.includes(formDetails.template) && <span className="text-danger">*</span>}</Label>
                                        <Col sm="7">
                                            <FormGroup>
                                                <Select
                                                    isMulti
                                                    options={
                                                        (matchOptions?.length ? matchOptions : featuredMatches).map(item => ({
                                                            label: `${item.name} - ${item.sub_title} (${new Date(item.start_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                                                            value: item.slug,
                                                        }))
                                                    }
                                                    value={
                                                        (matchOptions?.length ? matchOptions : featuredMatches)
                                                            .filter(item => formDetails.matches?.includes(item.slug))
                                                            .map(item => ({
                                                                label: `${item.name} - ${item.sub_title} (${new Date(item.start_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                                                                value: item.slug,
                                                            }))
                                                    }
                                                    onChange={(selected) => {
                                                        setMatchError({ msg: "", state: "" });
                                                        setFormDetails({
                                                            ...formDetails,
                                                            matches: selected ? selected.map(option => option.value) : [],
                                                        });
                                                    }}
                                                    placeholder={
                                                        matchOptions?.length
                                                            ? "Select Matches"
                                                            : "Featured Matches"
                                                    }
                                                />
                                                {matchError.state === "has-danger" ? (
                                                    <label className="text-danger">
                                                        {matchError.msg}
                                                    </label>
                                                ) : null}
                                            </FormGroup>


                                        </Col>
                                    </Row>
                                )}

                <Row className="justify-content-center">
                  <Label for="layout" sm="2">
                    Layout
                  </Label>
                  <Col sm="7">
                    <FormGroup className={layoutState}>
                      <Select
                        options={layouts}
                        value={
                          formDetails.layout
                            ? layouts.find(
                              (option) => option.value === formDetails.layout
                            )
                            : null
                        }
                        onChange={(selectedOption) => {
                          setLayoutError("");
                          setLayoutState("");
                          setFormDetails((prev) => ({
                            ...prev,
                            layout: selectedOption.value,
                            widget_data: {
                              ...prev.widget_data,
                              bg_color: "#6c757d",
                            },
                            matches:
                              prev.template === "match" ? prev.matches : [],
                          }));
                        }}
                        placeholder="Select Layout"
                      />
                      {layoutState === "has-danger" ? (
                        <label className="error">{layoutError}</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Label for="customCss" sm="2">
                    Custom CSS
                  </Label>
                  <Col sm="7">
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="custom_css"
                        id="custom_css"
                        value={formDetails.custom_css}
                        onChange={handleInputChange}
                        placeholder="Enter Custom CSS"
                        autoComplete="off"
                        rows="5"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Label for="is_clickable" sm="2">
                    Is Clickable
                  </Label>
                  <Col sm="7">
                    <FormGroup style={{ textAlign: "left" }}>
                      <Switch
                        name="is_clickable"
                        offColor="danger"
                        onColor="success"
                        offText={<i className="nc-icon nc-simple-remove" />}
                        onText={<i className="nc-icon nc-check-2" />}
                        value={formDetails.widget_data.is_clickable}
                        onChange={() =>
                          setFormDetails((prev) => ({
                            ...prev,
                            widget_data: {
                              ...prev.widget_data,
                              is_clickable: !prev.widget_data.is_clickable,
                              url_type: !prev.widget_data.is_clickable
                                ? "prefix_url"
                                : "",
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* Show Radio Buttons if Clickable */}
                {formDetails.widget_data.is_clickable && (
                  <Row className="justify-content-center align-items-center">
                    <Label sm="2">URL Type</Label>
                    <Col sm="7">
                      <FormGroup className="d-flex gap-4">
                        <Label
                          check
                          className="d-flex align-items-center gap-1"
                        >
                          <Input
                            type="radio"
                            name="url_type"
                            value="prefix_url"
                            checked={
                              formDetails.widget_data.url_type === "prefix_url"
                            }
                            onChange={(e) =>
                              setFormDetails((prev) => ({
                                ...prev,
                                widget_data: {
                                  ...prev.widget_data,
                                  url_type: e.target.value,
                                },
                              }))
                            }
                          />
                          Prefix URL
                        </Label>
                        <Label
                          check
                          className="d-flex align-items-center gap-1"
                        >
                          <Input
                            type="radio"
                            name="url_type"
                            value="full_url"
                            checked={
                              formDetails.widget_data.url_type === "full_url"
                            }
                            onChange={(e) =>
                              setFormDetails((prev) => ({
                                ...prev,
                                widget_data: {
                                  ...prev.widget_data,
                                  url_type: e.target.value,
                                },
                              }))
                            }
                          />
                          Full URL
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {/* Show Prefix URL Input */}
                {formDetails.widget_data.is_clickable &&
                  formDetails.widget_data.url_type === "prefix_url" && (
                    <Row className="justify-content-center">
                      <Label for="prefix_url" sm="2">
                        Prefix URL
                      </Label>
                      <Col sm="7">
                        <FormGroup style={{ textAlign: "left" }}>
                          <Input
                            type="text"
                            name="prefix_url"
                            id="prefix_url"
                            value={formDetails.widget_data.prefix_url}
                            autoComplete="off"
                            onChange={(e) =>
                              setFormDetails((prev) => ({
                                ...prev,
                                widget_data: {
                                  ...prev.widget_data,
                                  prefix_url: e.target.value,
                                },
                              }))
                            }
                            placeholder="Enter Prefix URL"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                {/* Show Full URL Input */}
                {formDetails.widget_data.is_clickable &&
                  formDetails.widget_data.url_type === "full_url" && (
                    <Row className="justify-content-center">
                      <Label for="full_url" sm="2">
                        Full URL
                      </Label>
                      <Col sm="7">
                        <FormGroup style={{ textAlign: "left" }}>
                          <Input
                            type="text"
                            name="full_url"
                            id="full_url"
                            value={formDetails.widget_data.full_url}
                            autoComplete="off"
                            onChange={(e) =>
                              setFormDetails((prev) => ({
                                ...prev,
                                widget_data: {
                                  ...prev.widget_data,
                                  full_url: e.target.value,
                                },
                              }))
                            }
                            placeholder="Enter Full URL"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                <Row className="justify-content-center">
                  <Label for="auto_refresh" sm="2">
                    Auto Refresh Widget
                  </Label>
                  <Col sm="7">
                    <FormGroup style={{ textAlign: "left" }}>
                      <Switch
                        name="auto_refresh"
                        offColor="danger"
                        onColor="success"
                        offText={<i className="nc-icon nc-simple-remove" />}
                        onText={<i className="nc-icon nc-check-2" />}
                        value={formDetails.widget_data.auto_refresh}
                        onChange={() =>
                          setFormDetails((prev) => ({
                            ...prev,
                            widget_data: {
                              ...prev.widget_data,
                              auto_refresh: !prev.widget_data.auto_refresh,
                              // url_type: !prev.widget_data.is_clickable ? "prefix_url" : "",
                            },
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {formDetails.widget_data.auto_refresh && (
                  <Row className="justify-content-center">
                    <Label for="refresh_widget_time" sm="2">
                      Refresh Duration <span class="text-danger"> * </span>
                    </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Input
                          type="text"
                          name="refresh_widget_time"
                          id="refresh_widget_time"
                          value={formDetails.widget_data.refresh_widget_time}
                          onChange={handleInputChange}
                          placeholder="Enter refresh duration in seconds"
                          autoComplete="off"
                        />
                        {refreshTimeError.state === "has-danger" ? (
                          <label className="text-danger">
                            {refreshTimeError.msg}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {(formDetails.template === "sidebar-tournament-card" || formDetails.template === "tournament") && (
                  <>
                    <Row className="justify-content-center">
                      <Label for="scheduled_widget" sm="2">Scheduled Matches Count</Label>
                      <Col sm="7">
                        <FormGroup>
                          <Input
                            type="text"
                            name="scheduled_widget"
                            id="scheduled_widget"
                            value={formDetails.widget_data.widgets_count.scheduled}
                            onChange={handleInputChange}
                            placeholder="Enter no. of scheduled match cards"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Label for="scheduled_widget" sm="2">Completed Matches Count</Label>
                      <Col sm="7">
                        <FormGroup>
                          <Input
                            type="text"
                            name="completed_widget"
                            id="completed_widget"
                            value={formDetails.widget_data.widgets_count.completed}
                            onChange={handleInputChange}
                            placeholder="Enter no. of completed match cards"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}

                {/* Show color picker only if layout is "listing" */}
                {formDetails.layout === "listing" && (
                  <Row className="mt-3 justify-content-center">
                    <Label for="bg_color" sm="2">
                      Background Color<span className="text-danger"> * </span>
                    </Label>
                    <Col sm="7">
                      <FormGroup style={{ position: "relative" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            border: "1px solid #ced4da",
                            borderRadius: "8px",
                            backgroundColor: "#f8f9fa",
                          }}
                        >
                          <Input
                            type="color"
                            name="bg_color"
                            id="bg_color"
                            value={formDetails.widget_data.bg_color}
                            autoComplete="off"
                            onChange={(e) =>
                              setFormDetails({
                                ...formDetails,
                                widget_data: {
                                  ...formDetails.widget_data,
                                  bg_color: e.target.value,
                                },
                              })
                            }
                            style={{
                              width: "50px",
                              height: "50px",
                              border: "none",
                              padding: "0",
                              background: "none",
                              cursor: "pointer",
                            }}
                          />
                          <div>
                            <div
                              style={{
                                fontWeight: "bold",
                                color:
                                  formDetails.widget_data.bg_color || "#6c757d",
                              }}
                            >
                              {formDetails.widget_data.bg_color || "#6c757d"}
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Label for="domains" sm="2">
                    Allowed Domains<span className="text-danger">*</span>
                  </Label>
                  <Col sm="7">
                    {formDetails.domains.map((item, index) => (
                      <FormGroup
                        key={index}
                        className="d-flex align-items-start gap-2 mb-2"
                      >
                        <div className="w-100">
                          <Input
                            type="text"
                            placeholder="Enter domain"
                            value={item.domain}
                            onChange={(e) =>
                              handleDomainChange(index, e.target.value)
                            }
                            className={
                              !item.isValid ? "border border-danger" : ""
                            }
                            autoComplete="off"
                          />
                          {index === formDetails.domains.length - 1 && (
                            <FormText>
                              Enter domain like <code>your_domain.com</code> or
                              use a wildcard for subdomains, e.g.,{" "}
                              <code>*.your_domain.com</code>.
                            </FormText>
                          )}
                        </div>

                        <Button
                          color="danger"
                          className="btnsmiconred"
                          onClick={() => handleRemoveDomain(index)}
                          disabled={formDetails.domains.length === 1}
                          title="Remove"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>

                        {index === formDetails.domains.length - 1 && (
                          <Button
                            color="primary"
                            className="btn-smb btnsmicon"
                            onClick={handleAddDomain}
                            title="Add New"
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                      </FormGroup>
                    ))}
                  </Col>
                </Row>

                {jsEmbedd && (
                  <Row className="mt-3 justify-content-center">
                    <Label for="bg_color" sm="2">
                      JS Embbed
                    </Label>
                    <Col sm="7">
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="textarea"
                            name="code"
                            id="codeTextArea"
                            placeholder="Your js embedd will appear here"
                            value={jsEmbedd}
                            readOnly
                          />
                          <InputGroupText
                            style={{
                              cursor: "pointer", // Add cursor style for hover effect
                              background: "transparent", // Make background transparent
                              border: "none", // Remove border
                            }}
                            onClick={handleCopy}
                          >
                            {copied ? (
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ fontSize: "1.2em", cursor: "pointer" }}
                                id="copy-icon"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCopy}
                                style={{ fontSize: "1.2em", cursor: "pointer" }}
                                id="copy-icon"
                              />
                            )}
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen}
                              target="copy-icon"
                              toggle={toggleTooltip}
                            >
                              {copied ? "Copied!" : "Copy"}
                            </Tooltip>
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-center">
                  <div>
                    <Button
                      className="btn-round"
                      color="info"
                      type="button"
                      disabled={disabled}
                      onClick={handleCreate}
                    >
                      Update
                    </Button>

                    <Button
                      className="btn-round"
                      color="danger"
                      type="button"
                      onClick={(event) => {
                        history.push(`/dashboard/cricket-widget`);
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      {formDetails.template !== "" && (
        <TemplateFormBuilder
          template={template}
          setFormData={setFormData}
          formData={formData}
          errors={errors}
          setErrors={setErrors}
          initialData={initialData}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default EditCricketWidget;
