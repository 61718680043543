import React, { useEffect, useState, useContext } from "react";
import projectsService from "../../services/api/projects-service";
import gamesService from "../../services/api/games-service.js";
import { useHistory } from "react-router-dom";
import Switch from "react-bootstrap-switch";
import UserContext from "../../UserContext";
import TimezoneSelect from 'react-timezone-select';
import TemplateChanged from "./TemplateChange.js";
import QuintypeWebhookConfigForm from './QuintypeWebhook.js'
import WebstoriesConfigForm from "./ProjectWebstories.js";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import Jwplayer from "./Jwplayer";
import ReactBSAlert from 'react-bootstrap-sweetalert';

var i18n = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Dawson': 'Pacific Time (US and Canada); Tijuana',
  'America/Boise': 'Mountain Time (US and Canada)',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time (US and Canada)',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time (US and Canada)',
  'America/Indiana/Indianapolis': 'Indiana (East)',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Glace_Bay': 'Atlantic Time (Canada)',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Etc/GMT+2': 'Mid-Atlantic',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Fiji': 'Fiji Islands, Kamchatka, Marshall Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa"
};


const ProjectSettings = (props) => {
  const projectId = new URLSearchParams(props.location.search).get('projectid');
  const history = new useHistory();
  if (!projectId) {
    history.push("/project/projects");
  }
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({
    projectid: projectId,
    nlp: false,
    good_news: false,
    paid_article: false,
    trending_articles: false,
    auto_notifications: false,
    brief_notification: false,
    sortd_apps: false,
    enable_ctv: false,
    manage_domain_enabled: false,
    webstory_only_dashboard: false,
    enable_category_in_article: false,
    enable_category_alias_url: false,
    self_canonical: false,
    enable_bulk_sync: false,
    shorts_category_id: "",
    cloud_provider: "",
    enable_article_view_count: false,
    enable_games: false,
    enable_redirection_urls: false,
    enable_article_slug_url: false
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [shortsError, setShortsError] = useState({ show: false, msg: "", type: "" })
  const [cloudProviderError, setCloudProviderError] = useState({ show: false, msg: "", type: "" });
  const [cloudSwitchPopup, setCloudSwitchPopup] = useState({ alert: null });
  const [cloudProvider, setCloudProvider] = useState("");
  const [reloadComponent, setReloadComponent] = useState(0);
  const AVAILABLE_GAMES = [
    { value: 'quiz', label: 'Quiz' },
    { value: 'headline_scramble', label: 'Headline Scramble' },
    { value: 'hangman', label: 'Hangman' },
    { value: 'crossword', label: 'Crossword' },
    { value: 'hangman', label: 'Hangman' },
    { value: 'poll', label: 'Poll' }
  ]
  const cloudProviderOptions = [
    {
      value: "aws",
      label: "AWS",
    },
    {
      value: "gcp",
      label: "Google Cloud",
    }
  ]

  useEffect(() => {
    (async () => {
      let getProjectDetails = await projectsService.getProjectDetails(projectId);
      if (getProjectDetails.status) {
        if (getProjectDetails.data.settings) {
          setFormDetails({
            ...formdetails,
            nlp: getProjectDetails.data.settings.nlp,
            good_news: getProjectDetails.data.settings.good_news,
            paid_article: getProjectDetails.data.settings.paid_article ? true : false,
            trending_articles: getProjectDetails.data.settings.trending_articles || false,
            auto_notifications: getProjectDetails.data.settings.auto_notifications || false,
            brief_notification: getProjectDetails.data.settings.brief_notification || false,
            sortd_apps: getProjectDetails.data.settings.sortd_apps ? true : false,
            enable_ctv: getProjectDetails.data.settings.enable_ctv ? true : false,
            manage_domain_enabled: getProjectDetails.data.settings.manage_domain_enabled ? true : false,
            webstory_only_dashboard: getProjectDetails.data.settings.webstory_only_dashboard ? true : false,
            enable_category_in_article: getProjectDetails.data.settings.enable_category_in_article ? true : false,
            enable_category_alias_url: getProjectDetails.data.settings.enable_category_alias_url ? true : false,
            self_canonical: getProjectDetails.data.settings.self_canonical ? true : false,
            enable_bulk_sync: getProjectDetails.data.enable_bulk_sync ? true : false,
            shorts_category_id: getProjectDetails.data.settings.shorts_category_id || '',
            cloud_provider: getProjectDetails.data.settings.cloud_provider || "",
            enable_article_view_count: getProjectDetails.data.enable_article_view_count || false,
            enable_games: getProjectDetails.data.settings.enable_games ? true : false,
            enable_redirection_urls: getProjectDetails.data.settings.enable_redirection_urls ? true : false,
            enable_membership_subscription: getProjectDetails.data.enable_membership_subscription ? true : false,
            enable_article_slug_url: getProjectDetails.data.settings.enable_article_slug_url ? true : false,
            enable_slug_ai_translate: getProjectDetails.data.settings.enable_slug_ai_translate ? true : false,
          });
          setCloudProvider(getProjectDetails.data.settings.cloud_provider || "");
        }

      } else {
        history.push("/project/projects");
      }

    })();
  }, [reloadComponent]);
  const handleSwitchChange = (fieldname, fieldvalue) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: fieldvalue,
    });
  }
  const handleInputChange = (event, fieldname) => {
    setFormDetails({
      ...formdetails,
      [fieldname]: event.target.value,
    });
    setShortsError({ show: false, msg: "", type: "has-success" })
  }

  const changeCloudProvider = async (newCloudProvider) => {
    let res = await projectsService.updateCloudProvider({ cloud_provider: newCloudProvider, project_id: formdetails.projectid });
    if (!res.status && res.error.errorCode === 403) {
      context.setIsAuthorized(context);
    }
    else if (res.status && res.data) {
      setCloudSwitchPopup({
        alert: (
          <ReactBSAlert
            success
            title="Updated!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Cloud Provider updated successfully!
          </ReactBSAlert>
        )
      })
    } else {
      setCloudSwitchPopup({
        alert: (
          <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Please try again!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Cloud Provider could not be updated. Please try again.
          </ReactBSAlert>
        )
      })
    }

    setReloadComponent(Math.random())
  }

  const hideAlert = () => {
    setCloudSwitchPopup({
      alert: null
    })
  }
  const cloudProviderChangePopup = async (newCloudProvider) => {
    setCloudSwitchPopup({
      alert: (
        <ReactBSAlert
          warning
          title="Are you sure?"
          onConfirm={() => changeCloudProvider(newCloudProvider)}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, change it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You want to change the Cloud Provider!
        </ReactBSAlert>
      )
    })
  }
  const handleSelectChange = (event) => {
    const res = cloudProviderChangePopup(event.value);
    setCloudProviderError({ show: false, msg: "", type: "has-success" })

  }
  const handleSubmit = function (e) { validateForm(); e.preventDefault() }

  const settingsFormValidations = () => {
    if (formdetails.shorts_category_id !== "" && formdetails.shorts_category_id.trim() === "") {
      setShortsError({ show: true, msg: "Please enter correct id", type: "has-danger" })
      return false;
    }
    if (formdetails.cloud_provider !== "" && formdetails.cloud_provider.trim() === "") {
      setCloudProviderError({ show: true, msg: "Please select correct cloud provider", type: "has-danger" })
      return false;
    }
    return true;
  }
  // const validateForm = async () => {
  //   if(settingsFormValidations()){
  //     setShowLoader(true);
  //     setShowSaveButton(false);

  //     let saveProjectSettings = await projectsService.saveProjectSettings(formdetails);
  //     if(!saveProjectSettings.status && saveProjectSettings.error.errorCode === 403){
  //         context.setIsAuthorized(context);
  //     }
  //     else if(saveProjectSettings.status){
  //       setTimeout(()=>{
  //         setShowLoader(false)
  //         setShowSaveButton(true);
  //         setAlert({show:true,type:'success',message:'Project settings were saved successfully'});
  //       },1000);

  //     }else{
  //       setTimeout(()=>{
  //         setShowLoader(false)
  //         setShowSaveButton(true)
  //         setAlert({show:true,type:'danger',message:'Project settings could not be saved, Please try again.'});
  //       },1000);
  //     }

  //     setTimeout(()=>{
  //       setAlert({show:false,type:'',message:''});
  //     },3000);

  //     // setTimeout(()=>{
  //     //   history.push("/project/projects");
  //     // },2000);
  //   }
  // }

  const validateForm = async () => {
    if (!settingsFormValidations()) {
      setAlert({
        show: true,
        type: 'danger',
        message: 'Form validation failed. Please correct the errors.',
      });
      setTimeout(() => setAlert({ show: false, type: '', message: '' }), 3000);
      return;
    }

    const handleAlert = (type, message) => {
      setAlert({ show: true, type, message });
      setTimeout(() => setAlert({ show: false, type: '', message: '' }), 3000);
    };

    const handleSaveState = (isLoading, showButton) => {
      setShowLoader(isLoading);
      setShowSaveButton(showButton);
    };

    try {
      handleSaveState(true, false);
      const gamesSettings = await gamesService.saveGamesSettings({
        project_id: projectId,
        status: formdetails.enable_games,
      });

      if (!gamesSettings) {
        handleAlert('danger', 'No response received for saving games settings.');
        return;
      }

      if (!gamesSettings.status) {
        handleAlert('danger', 'Games settings could not be saved, Please try again.');
        return;
      }


      const saveProjectSettings = await projectsService.saveProjectSettings(formdetails);

      if (!saveProjectSettings) {
        handleAlert('danger', 'No response received for saving project settings.');
        return;
      }

      if (!saveProjectSettings.status) {
        if (saveProjectSettings.error.errorCode === 403) {
          context.setIsAuthorized(context);
        } else {
          handleAlert('danger', 'Project settings could not be saved, Please try again.');
        }
      } else {
        handleAlert('success', 'Project settings were saved successfully');
      }
    } catch (error) {
      console.error('Error while validating form:', error);
      handleAlert('danger', 'An unexpected error occurred. Please try again.');
    } finally {
      handleSaveState(false, true);
    }
  };

  return (

    <div className="content">

      {cloudSwitchPopup.alert}
      <Container>
        <TemplateChanged projectId={projectId} />
        <QuintypeWebhookConfigForm projectId={projectId} />
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card className="card-signup text-center">
              <CardHeader>
                <CardTitle tag="h4">Project Settings</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontol" action="" id="projectSetting" method="POST" onSubmit={handleSubmit}>

                  <Row>
                    <Label sm="2">NLP</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="nlp"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.nlp}
                          onChange={(value, enabled) => handleSwitchChange('nlp', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Good News</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="good_news"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.good_news}
                          onChange={(value, enabled) => handleSwitchChange('good_news', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable Paid Article </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="paid_article"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.paid_article}
                          onChange={(value, enabled) => handleSwitchChange('paid_article', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable Trending Articles </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="trending_articles"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.trending_articles}
                          onChange={(value, enabled) => handleSwitchChange('trending_articles', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable Auto Notifications </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="auto_notifications"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.auto_notifications}
                          onChange={(value, enabled) => handleSwitchChange('auto_notifications', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable Brief Notifications </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="brief_notification"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.brief_notification}
                          onChange={(value, enabled) => handleSwitchChange('brief_notification', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable SORTD Apps </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="sortd_apps"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.sortd_apps}
                          onChange={(value, enabled) => handleSwitchChange('sortd_apps', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Enable Connected TV Apps </Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="sortd_apps"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_ctv}
                          onChange={(value, enabled) => handleSwitchChange('enable_ctv', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Enable Manage Domain</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="sortd_apps"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.manage_domain_enabled}
                          onChange={(value, enabled) => handleSwitchChange('manage_domain_enabled', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable Webstory Only Dashboard</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="webstory_only_dashboard"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.webstory_only_dashboard}
                          onChange={(value, enabled) => handleSwitchChange('webstory_only_dashboard', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Enable Category in Article</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_category_in_article"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_category_in_article}
                          onChange={(value, enabled) => handleSwitchChange('enable_category_in_article', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Enable Category Alias Url</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_category_alias_url"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_category_alias_url}
                          onChange={(value, enabled) => handleSwitchChange('enable_category_alias_url', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Self Canonical</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="self_canonical"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.self_canonical}
                          onChange={(value, enabled) => handleSwitchChange('self_canonical', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Label sm="2">Enable Bulk Sync</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_bulk_sync"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_bulk_sync}
                          onChange={(value, enabled) => handleSwitchChange('enable_bulk_sync', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Article Views Count</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_article_view_count"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_article_view_count}
                          onChange={(value, enabled) => handleSwitchChange('enable_article_view_count', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Enable Games</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_games"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_games}
                          onChange={(value, enabled) => handleSwitchChange('enable_games', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row >
                  <Row>
                    <Label sm="2">Enable Redirection Urls</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_redirection_urls"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_redirection_urls}
                          onChange={(value, enabled) => handleSwitchChange('enable_redirection_urls', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Membership subscription</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_membership_subscription"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_membership_subscription}
                          onChange={(value, enabled) => handleSwitchChange('enable_membership_subscription', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row >
                  <Row>
                    <Label sm="2">Enable Article Slug URL</Label>
                    <Col sm="7">
                      <FormGroup>
                        <Switch name="enable_article_slug_url"
                          offColor="success"
                          offText={<i className="nc-icon nc-simple-remove" />}
                          onColor="success"
                          onText={<i className="nc-icon nc-check-2" />}
                          value={formdetails.enable_article_slug_url}
                          onChange={(value, enabled) => handleSwitchChange('enable_article_slug_url', enabled)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Shorts Category Id</Label>
                    <Col sm="7">
                      <FormGroup className={shortsError.type}>
                        <Input name="shorts_category_id"
                          value={formdetails.shorts_category_id}
                          onChange={(event) => handleInputChange(event, 'shorts_category_id')}
                        />
                        {shortsError.show ? (
                          <label className="error">
                            {shortsError.msg}
                          </label>
                        )
                          : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Select Cloud Provider</Label>
                    <Col sm="7">
                      <FormGroup className={cloudProviderError.type}>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="cloud_provider"
                          name="cloud_provider"
                          options={cloudProviderOptions}
                          value={formdetails.cloud_provider !== "" ? cloudProviderOptions.filter(option => option.value === formdetails.cloud_provider) : ""}
                          onChange={handleSelectChange}
                        />
                        {cloudProviderError.show ? (
                          <label className="error">
                            {cloudProviderError.msg}
                          </label>
                        )
                          : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  {showSaveButton ? <Button
                    className="btn-round"
                    color="info"
                    type="button"
                    onClick={validateForm}
                  >
                    Save
                  </Button> : ''}
                  {alert.show ? <Alert color={alert.type}>
                    {alert.message}
                  </Alert> : ''}

                  {showLoader ? <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner> : ''}

                </Form>
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card >
          </Col >
        </Row >
      </Container >
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
        }}
      />
      <Jwplayer props={props} />
      <WebstoriesConfigForm projectId={projectId} formdetails={formdetails} />
    </div >


  );

}

export default ProjectSettings;
