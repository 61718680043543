import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  CardTitle,
  Input,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap";

import transactionsService from "../../services/api/transaction-membership-service";
import subscriptionsService from "../../services/api/subscription-membershipl-service";
import projectsService from "../../services/api/projects-service";
import projectUsersService from "../../services/api/project-users-service";
import Projects from "../projects/Projects";

const RECORDS_PER_PAGE = 10;

const STATUS_OPTIONS = [
  { value: "created", label: "Created" },
  { value: "authenticated", label: "Authenticated" },
  { value: "activated", label: "Activated" },
  { value: "charged", label: "Charged" },
  { value: "completed", label: "Completed" },
  { value: "pending", label: "Pending" },
  { value: "halted", label: "Halted" },
  { value: "cancelled", label: "Cancelled" },
  { value: "paused", label: "Paused" },
  { value: "resumed", label: "Resumed" },
];

const initialFormState = {
  user_id: "",
  subscription_id: "",
  from_date: "",
  to_date: "",
  status: ["activated", "charged"],
};

const Subscriptions = () => {
  const location = useLocation();
  const projectId = useMemo(
    () => new URLSearchParams(location.search).get("projectid"),
    [location.search]
  );

  // Main state
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [alert, setAlert] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [modalData, setModalData] = useState({
    transactions: [],
    totalTransactions: 0,
    isLoading: false,
    currentPage: 1
  });

  // Form state
  const [formState, setFormState] = useState({
    ...initialFormState,
    project_id: projectId,
  });

  // Fetch project details
  useEffect(() => {
    if (!projectId) return;

    const fetchProjectDetails = async () => {
      try {
        const result = await projectsService.getProjectDetails(projectId);
        if (result?.status) {
          setProjectDetails(result.data);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  // Fetch users
  useEffect(() => {
    if (!projectId) return;

    const fetchUsers = async () => {
      try {
        const users = await projectUsersService.fetchUsers({
          project_id: projectId,
          page: 1,
          page_size: "",
        });
        if (users?.status && users?.data?.users) {
          setUserOptions(
            users.data.users.map((user) => ({
              value: user.user_id,
              label: user.email ? `${user.name} (${user.email})` : `${user.name} ${user.phone_number}`,
              email: user.email,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [projectId]);

  // Fetch subscriptions
  const fetchSubscriptions = useCallback(async () => {
    if (!projectId) return;

    setIsLoading(true);
    try {
      const response = await subscriptionsService.getSubscriptions(
        formState,
        activePage,
        RECORDS_PER_PAGE
      );

      if (response.status && response.data) {
        console.log(response.data.subscriptions)
        setSubscriptions(response.data.subscriptions);
        setTotalSubscriptions(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching Memberships:", error);
      setAlert(createAlert("error", "Failed to fetch Memberships"));
    } finally {
      setIsLoading(false);
    }
  }, [projectId, formState, activePage]);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  // Fetch transactions for modal
  const fetchTransactions = useCallback(async (subscriptionId, page = 1) => {
    if (!subscriptionId) return;

    setModalData(prev => ({ ...prev, isLoading: true }));
    try {
      const [transactionsResponse, countResponse] = await Promise.all([
        transactionsService.getTransactions(
          { project_id: projectId, subscription_id: subscriptionId },
          page,
          RECORDS_PER_PAGE
        ),
        transactionsService.getTotalTransactions({
          project_id: projectId,
          subscription_id: subscriptionId
        })
      ]);

      if (transactionsResponse.status && countResponse.status) {
        setModalData(prev => ({
          ...prev,
          transactions: transactionsResponse.data,
          totalTransactions: countResponse.data.count,
          currentPage: page,
          isLoading: false
        }));
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setModalData(prev => ({ ...prev, isLoading: false }));
    }
  }, [projectId]);

  // Handle subscription click
  const handleSubscriptionClick = useCallback((subscriptionId) => {
    setSelectedSubscriptionId(subscriptionId);
    setIsModalOpen(true);
    fetchTransactions(subscriptionId);
  }, [fetchTransactions]);

  // Handle modal pagination
  const handleModalPageChange = useCallback((pageNumber) => {
    fetchTransactions(selectedSubscriptionId, pageNumber);
  }, [selectedSubscriptionId, fetchTransactions]);

  // Form handlers
  const handleFormChange = useCallback((field, value) => {
    setFormState(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleFilterReset = useCallback(() => {
    setFormState({ ...initialFormState, project_id: projectId });
    setActivePage(1);
  }, [projectId]);

  const handleFilterSubmit = useCallback((e) => {
    e.preventDefault();
    setActivePage(1);
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const confirmCancel = useCallback((subscription) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleSubscriptionCancel(subscription.pg_subscription_id)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, Cancel it!"
        cancelBtnText="Back"
        showCancel
      >
        <p>You want to cancel this Membership!</p>
        <hr />
        <p>
          <strong>Email:</strong> {subscription.user_id?.email || "N/A"}
        </p>
        <p>
          <strong>Subscription ID:</strong>{" "}
          {subscription.pg_subscription_id || "N/A"}
        </p>
        <p>
          <strong>Paid Count:</strong> {subscription.paid_count}
        </p>
        <p>
          <strong>Type:</strong>
          {subscription?.total_count === 1
            ? "One Time"
            : subscription?.total_count
            ? "Recurring"
            : "N/A"}
        </p>
        <p>
          <strong>Status:</strong> {subscription.status}
        </p>
      </ReactBSAlert>
    );
  }, []);

  const handleSubscriptionCancel = useCallback(async (subscriptionId) => {
    setAlert(null);
    try {
      setAlert(createAlert("loading", "Cancelling your Membership..."));
      const response = await subscriptionsService.cancelSubscription(subscriptionId);
      
      if (response.status) {
        setSubscriptions(prevSubs =>
          prevSubs.map(sub =>
            sub.pg_subscription_id === subscriptionId
              ? { ...sub, status: "cancelled" }
              : sub
          )
        );
        setAlert(createAlert("success", "Membership cancelled successfully"));
        await fetchSubscriptions();
      }
    } catch (error) {
      console.error("Error canceling Membership:", error);
      setAlert(createAlert("error", "Failed to cancel Membership. Please try again."));
    }
  }, [fetchSubscriptions]);

  const createAlert = useCallback((type, message) => {
    const alertProps = {
      style: { display: "block", marginTop: "-100px" },
      onConfirm: () => setAlert(null),
      onCancel: () => setAlert(null),
    };

    switch (type) {
      case "loading":
        return (
          <ReactBSAlert
            info
            {...alertProps}
            showConfirm={false}
            title="Processing..."
          >
            <div className="d-flex flex-column align-items-center">
              <Spinner className="mb-3" />
              <p>{message}</p>
            </div>
          </ReactBSAlert>
        );
      case "success":
        return (
          <ReactBSAlert
            success
            {...alertProps}
            title="Success"
            confirmBtnBsStyle="success"
          >
            {message}
          </ReactBSAlert>
        );
      case "error":
        return (
          <ReactBSAlert
            danger
            {...alertProps}
            title="Error"
            confirmBtnBsStyle="danger"
          >
            {message}
          </ReactBSAlert>
        );
      default:
        return null;
    }
  }, []);

  const getStatusText = useCallback((status) => {
    const statusMap = {
      0: "Initiated",
      "-1": "Order could not created",
      2: "Success",
      "-2": "Failed"
    };
    return statusMap[status] || "";
  }, []);

  if (!projectId) {
    return <Projects navigate="membership/subscription" />;
  }

  return (
    <div className="content">
      {alert}
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <CardTitle tag="h4">Membership Dashboard</CardTitle>
                  <div className="text-muted">
                    Project: <strong>{projectDetails.name}</strong>
                  </div>
                </div>

                <Form onSubmit={handleFilterSubmit}>
                  <Row>
                    <Col md="3">
                      <Select
                        isClearable
                        placeholder="Select User"
                        options={userOptions}
                        value={
                          userOptions.find(
                            (option) => option.value === formState.user_id
                          ) || null
                        }
                        onChange={(option) =>
                          handleFormChange("user_id", option?.value || "")
                        }
                       
                      />
                    </Col>
                    <Col md="3">
                      <ReactDatetime
                        value={formState.from_date}
                        onChange={(date) =>
                          handleFormChange("from_date", date._d)
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "From Date",
                         
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <ReactDatetime
                        value={formState.to_date}
                        onChange={(date) =>
                          handleFormChange("to_date", date._d)
                        }
                        inputProps={{
                          className: "form-control",
                          placeholder: "To Date",
                        
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        placeholder="Membership ID"
                        value={formState.subscription_id}
                        onChange={(e) =>
                          handleFormChange("subscription_id", e.target.value)
                        }
                        
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="3">
                      <Select
                        isMulti
                        isClearable
                        placeholder="Select Status"
                        options={STATUS_OPTIONS}
                        value={STATUS_OPTIONS.filter((option) =>
                          formState.status.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "status",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                       
                      />
                    </Col>
                    <Col md="6">
                      <Button
                        className="btn-round mr-2"
                        color="info"
                        type="submit"
                       
                      >
                        Search
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={handleFilterReset}
                       
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {totalSubscriptions > 0 ? (
                  <Row>
                    <Col md="6">
                      <div style={{ float: "right", margin: "5px" }}>
                        <b>
                          {" "}
                          {(activePage - 1) * RECORDS_PER_PAGE + 1} -{" "}
                          {Math.min(
                            activePage * RECORDS_PER_PAGE,
                            totalSubscriptions
                          )}{" "}
                          of {totalSubscriptions}
                        </b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "left" }}>
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={RECORDS_PER_PAGE}
                          totalItemsCount={totalSubscriptions}
                          pageRangeDisplayed={3}
                          onChange={setActivePage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>#</th>
                      <th>User Email or Phone No.</th>
                      <th>Membership ID</th>
                      <th>Paid Count</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : subscriptions.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <CardTitle tag="h4">No Records Found</CardTitle>
                        </td>
                      </tr>
                    ) : (
                      subscriptions.map((subscription, index) => (
                        <tr key={subscription._id}>
                          <td>
                            {(activePage - 1) * RECORDS_PER_PAGE + index + 1}
                          </td>
                          <td>{subscription.user_id?.email || subscription?.user_id?.phone_number || ""}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSubscriptionClick(subscription._id)
                            }
                          >
                            {subscription.pg_subscription_id || ""}
                          </td>

                          <td>{subscription.paid_count}</td>
                          <td>
                            {subscription?.total_count === 1
                              ? "One Time"
                              : subscription?.total_count
                              ? "Recurring"
                              : ""}
                          </td>
                          <td>{subscription.status}</td>
                          <td>
                            {subscription?.createdAt
                              ? moment
                                  (subscription?.createdAt)
                                  .format("DD MMM YYYY hh:mm:ss a")
                              : ""}
                          </td>
                          <td>
                            {subscription?.start_at
                              ? moment
                                  .unix(subscription?.start_at)
                                  .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>
                            {subscription?.end_at
                              ? moment
                                  .unix(subscription?.end_at)
                                  .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>
                            {subscription.status !== "cancelled" && subscription.share_url !== "" &&
                              !subscription.pg_subscription_id?.startsWith(
                                "order_"
                              ) && (
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => confirmCancel(subscription)}
                                
                                >
                                  Cancel
                                </Button>
                              )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </CardBody>
              <Modal
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                size="lg"
              >
                <ModalBody className="p-3 position-relative">
                  {/* Close Button */}
                  <div
                    className="position-absolute d-flex align-items-center justify-content-center"
                    style={{
                      right: "12px",
                      top: "8px",
                      cursor: "pointer",
                      zIndex: 1,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={() => setIsModalOpen(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} className="text-white" />
                  </div>

                  {/* Transaction Details Section */}
                  <div className="mb-3">
                    <h5 className="mb-3">Transaction Details</h5>

                    <div className="border rounded">
                      {isLoading ? (
                        <div className="text-center p-4">
                          <Spinner />
                        </div>
                      ) : modalData.transactions.length > 0 ? (
                        <>
                          <Table responsive >
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left">#</th>
                                <th className="text-left">User Name</th>
                                <th className="text-left">
                                  Transaction Amount(INR)
                                </th>
                                <th className="text-left">
                                  Effective Amount(INR)
                                </th>
                                <th className="text-left">Transaction Id</th>
                                <th className="text-left">Transaction Date</th>
                                <th className="text-left">Type</th>
                                <th className="text-center">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {modalData.transactions.map((transaction, index) => {
                                const rowNumber =
                                  RECORDS_PER_PAGE * (activePage - 1) + index + 1;
                             
                                return (
                                  <tr key={transaction.id} >
                                    <td className="text-left">{rowNumber}</td>
                                    <td className="text-left">
                                      {transaction?.user_id?.name || ""}
                                    </td>
                                    <td className="text-left">
                                      ₹{((transaction?.amount || 0) / 100).toFixed(2)}
                                    </td>
                                    <td className="text-left">
                                      ₹
                                      {(
                                        (transaction?.amount || 0) -
                                        (transaction?.coupon_discount || 0)
                                      ) / 100}
                                    </td>
                                    <td className="text-left">
                                      {transaction.pg_txn_id || ""}
                                    </td>
                                    <td className="text-left">
                                      <Moment format="DD MMM YYYY hh:mm:ss a">
                                        {transaction.createdat}
                                      </Moment>
                                    </td>
                                    <td className="text-left">
                                      {transaction.type === "1"
                                        ? "One time"
                                        : "Recurring"}
                                    </td>
                                    <td className="text-center">
                                      {getStatusText(transaction.status)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <div className="text-center p-4">
                          <p>No transactions found for this subscription.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Subscriptions;
