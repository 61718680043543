import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import {
  faInfo,
  faTrash,
  faTimes,
  faTrashRestoreAlt,
  faPencilAlt,
  faCheckCircle,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Button,
  CardBody,
  Table,
  ModalHeader,
  ModalBody,
  Modal,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  Tooltip,
  Form,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import widgetsService from "../../services/api/widget-builder-service";
import generalHelper from "../../variables/general-helper.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify"; // Added for error handling
import { template } from "lodash";

const CricketWidget = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [detailedWidget, setDetailedWidget] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [totalWidgets, setTotalWidgets] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [alert, setAlert] = useState("");
  const [embedModal, setEmbedModal] = useState(false);
  const [selectedEmbedCode, setSelectedEmbedCode] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeTooltipId, setActiveTooltipId] = useState(null);
  const [copied, setCopied] = useState(false);
  const [tournaments, setTournamentOptions] = useState([]);
  const [matches, setMatchOptions] = useState([]);
  const [featuredMatches, setFeaturedMatches] = useState([]);
  const records = 10;

  const initialFormState = {
    title: "",
    widget_name: "",
    client_name: "",
    template: "",
    layout: "",
    from_date: "",
    to_date: "",
    tournaments: [],
    matches: [],
    featuredMatches: [],
  };

  const LAYOUT = [
    { label: "Listing", value: "listing" },
    { label: "Carousel", value: "carousel" },
  ];
  const TEMPLATE = [
    { label: "Tournament", value: "tournament" },
    { label: "Match", value: "match" },
    { label: "Sidebar-Tournament-Points Table", value: "sidebar-tournament-points-table" },
    { label: "Sidebar-Match-Team Innings", value: "sidebar-match-team-innings" },
    { label: "Sidebar-Tournament-Top Wicket Takers", value: "sidebar-tournament-top-wicket-takers" },
    { label: "Sidebar-Tournament-Top Run Scorer", value: "sidebar-tournament-top-run-scorer" },
    { label: "Sidebar-Tournament-Card", value: "sidebar-tournament-card" },
    { label: "Single Match Card", value: "single-match-card" },
    { label: "Tournament All Matches Carousel", value: "tournament-all-matches-carousel" },
    { label: "Match & Tournament Detail Carousel", value: "match-tournament-detail-carousel" },
    { label: "Match Detail Carousel", value: "match-detail-carousel" },
    { label: "Tournament Latest Match Carousel", value: "tournament-latest-match-carousel" },
    { label: "Full Match Detail", value: "full-match-detail" },
    { label: "Tournament Latest Match Full Detail", value: "tournament-latest-match-full-detail" },
  ];
  const [formState, setFormState] = useState(initialFormState);
  const [modalTooltipOpen, setModalTooltipOpen] = useState(false);

  // Memoize pagination data
  const paginationData = useMemo(() => {
    const firstRecord = (activePage - 1) * records + 1;
    const lastRecord = Math.min(activePage * records, totalWidgets);
    return { firstRecord, lastRecord };
  }, [activePage, totalWidgets]);

  // Fetch widgets with memoized dependency
  const fetchWidgets = useCallback(async () => {
    try {
      const widgetsData = await widgetsService.getWidgets(
        formState, // Using formState directly instead of formdetails
        activePage,
        records
      );

      if (widgetsData.status && widgetsData.data) {
        setWidgets(widgetsData.data.cricketWidget);
        setTotalWidgets(widgetsData.data.count);
      }
    } catch (error) {
      console.error("Error fetching widgets:", error);
      toast.error("Failed to load widgets. Please try again.");
    }
  }, [formState, activePage]);

  // Toggle tooltip with ID tracking
  const toggleTooltip = (id) => {
    setActiveTooltipId(id === activeTooltipId ? null : id);
    setTooltipOpen(!tooltipOpen);
  };

  const toggleModalTooltip = () => setModalTooltipOpen(!modalTooltipOpen);

  // Page change handler
  const handlePageChange = useCallback((pageNumber) => {
    generalHelper.addKeyToQueryString("page", pageNumber);
    setActivePage(pageNumber);
  }, []);

  // Modal handlers
  const handleEmbedOpen = useCallback((widget) => {
    setSelectedEmbedCode(widget.js_embed);
    setEmbedModal(true);
  }, []);

  const handleModalOpen = useCallback((widget) => {
    setDetailedWidget(widget);
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setEmbedModal(false);
    setCopied(false);
  }, []);

  // Copy to clipboard with debounce
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(selectedEmbedCode);
    setCopied(true);

    // Use setTimeout directly without storing the reference
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [selectedEmbedCode]);

  // Edit widget handler
  const handleEdit = useCallback(
    (id) => {
      history.push(`/dashboard/cricket-widget/edit/${id}`);
    },
    [history]
  );

  // Form change handler
  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);

  // Reset filter handler
  const handleFilterReset = useCallback(() => {
    setFormState(initialFormState);
    setActivePage(1);
  }, [initialFormState]);

  // Filter submit handler
  const handleFilterSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setActivePage(1);
      fetchWidgets();
    },
    [fetchWidgets]
  );
  // Confirmation dialog
  const confirmAction = useCallback((widget) => {
    const action = widget.status ? "delete" : "restore";

    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Are you sure you want to ${action} this widget?`}
        onConfirm={() => handleWidgetAction(widget._id, action)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={`Yes, ${action} it!`}
        cancelBtnText="Back"
        showCancel
      >
        <p>This action will {action} the selected widget.</p>
        <hr />
        <p>
          <strong>Widget Name:</strong> {widget.widget_name || "N/A"}
        </p>
        <p>
          <strong>Client Name:</strong> {widget.client_name || "N/A"}
        </p>
      </ReactBSAlert>
    );
  }, []);

  // Widget action handler (delete/restore)
  const handleWidgetAction = useCallback(
    async (id, action) => {
      setAlert(null);
      try {
        setAlert(
          <ReactBSAlert
            info
            style={{ display: "block", marginTop: "-100px" }}
            title={`${action === "delete" ? "Deleting" : "Restoring"
              } widget...`}
            showConfirm={false}
          />
        );

        const res = await widgetsService.deleteWidget({ id: id });

        if (res && res.status) {
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title={`Widget ${action === "delete" ? "deleted" : "restored"
                } successfully`}
              onConfirm={() => setAlert(null)}
            />
          );
        } else {
          setAlert(
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title={"Something went wrong. Please try again."}
              onConfirm={() => setAlert(null)}
            />
          );
        }

        fetchWidgets();
      } catch (error) {
        console.error(`Error ${action}ing widget:`, error);
        setAlert(
          <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={`Failed to ${action} widget. Please try again.`}
            onConfirm={() => setAlert(null)}
          />
        );
      }
    },
    [fetchWidgets]
  );

  // Load tournaments and matches data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tournamentsRes, matchesRes] = await Promise.all([
          widgetsService.getTournaments(),
          widgetsService.getFeaturedMatches(),
        ]);
        console.log(tournamentsRes.data.data);
        console.log(matchesRes.data.data.matches);
        setTournamentOptions(tournamentsRes.data.data);
        setFeaturedMatches(matchesRes.data.data.matches);
      } catch (err) {
        console.error("Error fetching data:", err);
        toast.error("Error fetching tournaments or matches!");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchMatches = async () => {
      if (formState.tournaments && formState.tournaments.length > 0) {
        try {
          const matchData = await Promise.all(
            formState.tournaments.map(async (tournamentId) => {
              const response = await widgetsService.getMatches(tournamentId);
              return response.status && response.data ? response.data.data : [];
            })
          );

          // Flatten the accumulated match data and set it to state
          setMatchOptions(matchData.flat());
          // console.log("Accumulated Matches:", matchData.flat());
        } catch (error) {
          console.error("Error fetching matches:", error);
        }
      }
    };

    fetchMatches();
  }, [formState.tournaments]);
  useEffect(() => {
    fetchWidgets();
  }, [activePage, fetchWidgets]);

  return (
    <div className="content">
      {alert}
      <Row>
        <Col className="ml-auto mr-auto" md="12">
          <Card className="card-signup text-center">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle tag="h4">Cricket Widget</CardTitle>
                <div>
                  <Button
                    className="btn-round"
                    color="danger"
                    size="sm"
                    type="button"
                    onClick={() =>
                      history.push("/dashboard/cricket-widget/create")
                    }
                  >
                    Create Widget
                  </Button>
                </div>
              </div>

              <Form onSubmit={handleFilterSubmit}>
                <Row>
                  <Col md="3">
                    <Input
                      type="text"
                      placeholder="Widget Name"
                      value={formState.widget_name}
                      onChange={(e) =>
                        handleFormChange("widget_name", e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      type="text"
                      placeholder="Publisher Name"
                      value={formState.client_name}
                      onChange={(e) =>
                        handleFormChange("client_name", e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      type="text"
                      placeholder="Title"
                      value={formState.title}
                      onChange={(e) =>
                        handleFormChange("title", e.target.value)
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Select
                      isMulti
                      options={featuredMatches.map((item) => ({
                        label: `${item.name} - ${item.sub_title} (${new Date(item.start_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                        value: item.slug,
                      }))}
                      value={featuredMatches
                        .filter((item) =>
                          formState.featuredMatches.includes(item.slug)
                        )
                        .map((item) => ({
                          label: `${item.name} - ${item.sub_title} (${new Date(item.start_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                          value: item.slug,
                        }))}
                      onChange={(selected) => {
                        handleFormChange(
                          "featuredMatches",
                          selected ? selected.map((opt) => opt.value) : []
                        );
                      }}
                      placeholder="Select Featured Matches"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="3">
                    <Select
                      isMulti
                      options={tournaments?.map((item) => ({
                        label: `${item.name} (${item.formats[0].replace(/^./, (char) => char.toUpperCase())}) (${new Date(item.start_date * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                        value: item.slug,
                      }))}
                      value={tournaments
                        ?.filter((item) =>
                          formState.tournaments.includes(item.slug)
                        )
                        .map((item) => ({
                          label: `${item.name} (${item.formats[0].replace(/^./, (char) => char.toUpperCase())}) (${new Date(item.start_date * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                          value: item.slug,
                        }))}
                      onChange={(selected) => {
                        handleFormChange(
                          "tournaments",
                          selected ? selected.map((opt) => opt.value) : []
                        );
                      }}
                      placeholder="Select Tournaments"
                    />
                  </Col>
                  <Col md="3">
                    <Select
                      isMulti
                      options={matches.map((item) => ({
                        label: `${item.name} - ${item.sub_title} (${new Date(item.start_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                        value: item.slug,
                      }))}
                      value={matches
                        .filter((item) => formState.matches.includes(item.slug))
                        .map((item) => ({
                          label: `${item.name} - ${item.sub_title} (${new Date(item.start_at * 1000).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })})`,
                          value: item.slug,
                        }))}
                      onChange={(selected) => {
                        handleFormChange(
                          "matches",
                          selected ? selected.map((opt) => opt.value) : []
                        );
                      }}
                      placeholder="Select Matches"
                    />
                  </Col>
                  <Col md="3">
                    <ReactDatetime
                      value={formState.from_date} // Ensure the current date is set if empty
                      onChange={(date) => handleFormChange("from_date", date._d)}
                      inputProps={{
                        className: "form-control",
                        placeholder: "From Date",
                      }}
                      // initialViewDate={new Date()} // Ensures current date is highlighted
                    />
                  </Col>
                  <Col md="3">
                    <ReactDatetime
                      value={formState.to_date}
                      onChange={(date) => handleFormChange("to_date", date._d)}
                      inputProps={{
                        className: "form-control",
                        placeholder: "To Date",
                      }}
                      // initialViewDate={new Date()}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="3">
                    <Select
                      isClearable
                      placeholder="Select Layout"
                      options={LAYOUT}
                      value={
                        LAYOUT.find(
                          (option) => option.value === formState.layout
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleFormChange(
                          "layout",
                          selectedOption ? selectedOption.value : ""
                        )
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Select
                      isClearable
                      placeholder="Select Template"
                      options={TEMPLATE}
                      value={
                        TEMPLATE.find(
                          (option) => option.value === formState.template
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleFormChange(
                          "template",
                          selectedOption ? selectedOption.value : ""
                        )
                      }
                    />
                  </Col>
                  <Col md="3">
                    <Button
                      className="btn-round mr-2"
                      color="info"
                      type="submit"
                    >
                      Search
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      type="button"
                      onClick={handleFilterReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />
              {totalWidgets > 0 && (
                <Row>
                  <Col md="6">
                    <div style={{ float: "right", margin: "5px" }}>
                      <b>
                        {paginationData.firstRecord} -{" "}
                        {paginationData.lastRecord} of {totalWidgets}
                      </b>
                    </div>
                  </Col>
                  <Col md="6">
                    <div style={{ float: "left" }}>
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={records}
                        totalItemsCount={totalWidgets}
                        pageRangeDisplayed={3}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </CardHeader>

            <CardBody>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th className="text-left">#</th>
                    <th className="text-left">Name</th>
                    <th className="text-left">Client</th>
                    <th className="text-left">Title</th>
                    <th className="text-left">Layout</th>
                    <th className="text-left">Template</th>
                    <th className="text-left">Updated At</th>
                    <th className="text-left">Created At</th>
                    <th className="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {widgets.length > 0 ? (
                    widgets.map((widget, index) => (
                      <tr key={widget._id || index}>
                        <td className="text-left">
                          {(activePage - 1) * records + index + 1}
                        </td>
                        <td className="text-left">{widget.widget_name}</td>
                        <td className="text-left">{widget.client_name}</td>
                        <td className="text-left">{widget.title || ""}</td>
                        <td className="text-left">{widget.layout}</td>
                        <td className="text-left">{widget.template}</td>
                        <td className="text-left">
                          {moment(widget.updatedAt).isValid()
                            ? moment(widget.updatedAt).format(
                              "MMMM D, YYYY h:mm A"
                            )
                            : ""}
                        </td>
                        <td className="text-left">
                          {moment(widget.createdAt).isValid()
                            ? moment(widget.createdAt).format(
                              "MMMM D, YYYY h:mm A"
                            )
                            : ""}
                        </td>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {widget.status ? (
                            <Button
                              onClick={() => confirmAction(widget)}
                              className="btnsmiconred"
                              title="Delete"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => confirmAction(widget)}
                              className="btn-smb btnsmicon"
                              title="Restore"
                            >
                              <FontAwesomeIcon icon={faTrashRestoreAlt} />
                            </Button>
                          )}

                          <div>
                            <Button
                              className="btn-smb btnsmicon"
                              color="link"
                              size="sm"
                              id={`copyEmbedBtn_${widget._id}`}
                              onClick={() => handleEmbedOpen(widget)}
                              title="Copy Embed Code"
                            >
                              <FontAwesomeIcon icon={faCopy} color="#ffffff" />
                            </Button>
                            <Tooltip
                              placement="top"
                              isOpen={activeTooltipId === widget._id}
                              target={`copyEmbedBtn_${widget._id}`}
                              toggle={() => toggleTooltip(widget._id)}
                            >
                              Copy JS Embed
                            </Tooltip>
                          </div>
                          <div>
                            <Button
                              className="btn-smb btnsmicon"
                              size="sm"
                              onClick={() => handleModalOpen(widget)}
                              title="Detailed View"
                            >
                              <FontAwesomeIcon icon={faInfo} color="#ffffff" />
                            </Button>
                          </div>
                          <div>
                            <Button
                              className="btn-smb btnsmicon"
                              size="sm"
                              onClick={() => handleEdit(widget._id)}
                              title="Edit"
                            >
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                color="#ffffff"
                              />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        <CardTitle tag="h4">No Records Found</CardTitle>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Embed Code Modal */}
      <Modal isOpen={embedModal} toggle={handleCloseModal} size="lg" centered >
        <ModalHeader className="border-0" >
          Copy Embed Code
          <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{
              right: "12px",
              top: "8px",
              cursor: "pointer",
              zIndex: 1,
              backgroundColor: "red",
              borderRadius: "50%",
              width: "25px",
              height: "25px",
            }}
            onClick={() => setEmbedModal(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" size="sm" />
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <InputGroup>
              <Input
                type="textarea"
                name="code"
                id="codeTextArea"
                placeholder="Your JS embed code will appear here"
                value={selectedEmbedCode}
                readOnly
                rows={15}
                style={{ fontSize: "0.9rem" }}
              />
              <InputGroupText
                style={{
                  cursor: "pointer",
                  background: "transparent",
                  border: "none",
                }}
                onClick={handleCopy}
              >
                <FontAwesomeIcon
                  icon={copied ? faCheckCircle : faCopy}
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  id="copyIconModal"
                />
              </InputGroupText>
            </InputGroup>
          </FormGroup>
          <Tooltip
            placement="top"
            isOpen={modalTooltipOpen}
            target="copyIconModal"
            toggle={toggleModalTooltip}
            container="body"
          >
            {copied ? "Copied!" : "Copy"}
          </Tooltip>
        </ModalBody>
      </Modal>

      {/* Details Modal */}
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} size="md">
        <ModalBody className="p-3 position-relative">
          {/* Close Button */}
          <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{
              right: "12px",
              top: "8px",
              cursor: "pointer",
              zIndex: 1,
              backgroundColor: "red",
              borderRadius: "50%",
              width: "25px",
              height: "25px",
            }}
            onClick={() => setModalOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </div>

          {/* Widget Details Section */}
          <div className="mb-3">
            <h6 className="mb-2">Cricket Widget Details</h6>
            <div className="border rounded">
              {[
                { label: "Widget Name", value: detailedWidget?.widget_name },
                { label: "Widget Slug", value: detailedWidget?.widget_slug },
                { label: "Publisher Name", value: detailedWidget?.client_name },
                { label: "Publisher Slug", value: detailedWidget?.client_slug },
                { label: "Title", value: detailedWidget?.title },
                { label: "Template", value: detailedWidget?.template },
                { label: "Layout", value: detailedWidget?.layout },
                {
                  label: "Tournaments",
                  value: (
                    <ul style={{ paddingLeft: "16px", margin: 0 }}>
                      {detailedWidget?.tournaments?.map((tournament, index) => (
                        <li key={index}>{tournament}</li>
                      ))}
                    </ul>
                  ),
                },
                {
                  label: "Matches",
                  value: (
                    <ul style={{ paddingLeft: "16px", margin: 0 }}>
                      {detailedWidget?.matches?.map((match, index) => (
                        <li key={index}>{match}</li>
                      ))}
                    </ul>
                  ),
                },
                {
                  label: "Status",
                  value: detailedWidget?.status ? "True" : "False",
                },
                {
                  label: "Is Clickable",
                  value: detailedWidget?.data?.is_clickable ? "True" : "False",
                },
                ...(detailedWidget?.data?.is_clickable
                  ? [
                    {
                      label:
                        detailedWidget?.data?.url_type === "full_url"
                          ? "Full URL"
                          : "Prefix URL",
                      value:
                        detailedWidget?.data?.url_type === "full_url"
                          ? detailedWidget?.data?.full_url || "-"
                          : detailedWidget?.data?.prefix_url || "-",
                    },
                  ]
                  : []),
                {
                  label: "Allowed Domains",
                  value: (
                    <ul className="mb-0 ps-3">
                      {detailedWidget?.domain?.map((domain, index) => (
                        <li key={index}>{domain.domain}</li>
                      ))}
                    </ul>
                  ),
                },
                { label: "Widget Code", value: detailedWidget?.widget_code },
                { label: "JS Embed", value: detailedWidget?.js_embed },
                {
                  label: "Created At",
                  value: moment(detailedWidget?.createdAt).isValid()
                    ? moment(detailedWidget?.createdAt).format(
                      "MMMM D, YYYY h:mm A"
                    )
                    : "",
                },
                {
                  label: "Updated At",
                  value: moment(detailedWidget?.updatedAt).isValid()
                    ? moment(detailedWidget?.updatedAt).format(
                      "MMMM D, YYYY h:mm A"
                    )
                    : "",
                },
              ].map((item, index, array) => (
                <Row
                  key={index}
                  className={`g-0 ${index > 0 ? "border-top" : ""}`}
                >
                  <Col xs="5" className="p-2 bg-light">
                    <div className="fw-semibold text-dark">{item.label}</div>
                  </Col>
                  <Col className="p-2">
                    <div className="fw-semibold">{item.value || ""}</div>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CricketWidget;
