import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert
} from 'reactstrap';

const GenAIAnalyticsDashboard = ({ data }) => {
  const chartRefs = {
    apiType: useRef(null),
    project: useRef(null),
    distribution: useRef(null),
    timeline: useRef(null)
  };

  const chartInstances = useRef({});

  const isValidData = (data) => {
    return Array.isArray(data) && data.length > 0 && data.every(item => 
      item && 
      typeof item === 'object' &&
      'api_type' in item &&
      'credit_consumed' in item &&
      'createdAt' in item
    );
  };

  useEffect(() => {
    // Cleanup previous charts
    Object.values(chartInstances.current).forEach(chart => {
      if (chart) chart.destroy();
    });

    if (!isValidData(data)) {
      console.log("Invalid or empty data received");
      return;
    }

    createCharts();

    return () => {
      Object.values(chartInstances.current).forEach(chart => {
        if (chart) chart.destroy();
      });
    };
  }, [data]);

  const createCharts = () => {
    const validData = data.filter(item => 
      item.api_type && 
      item.credit_consumed && 
      item.project_id && 
      item.project_id.name
    );

    if (validData.length === 0) return;

    createApiTypeChart(validData);
    createProjectChart(validData);
    createDistributionChart(validData);
    createTimelineChart(validData);
  };

  const createApiTypeChart = (validData) => {
    const ctx = chartRefs.apiType.current.getContext('2d');

    const groupedData = validData.reduce((acc, item) => {
      const key = item.api_type;
      if (!acc[key]) {
        acc[key] = { total: 0, count: 0 };
      }
      acc[key].total += item.credit_consumed;
      acc[key].count += 1;
      return acc;
    }, {});

    chartInstances.current.apiType = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Object.keys(groupedData),
        datasets: [{
          label: 'Credits Used',
          data: Object.values(groupedData).map(d => d.total),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const apiType = data.labels[tooltipItem.index];
              const info = groupedData[apiType];
              return [
                `Total Credits: ${info.total}`,
                `Number of Calls: ${info.count}`,
                `Average per Call: ${(info.total / info.count).toFixed(2)}`
              ];
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true }
          }]
        }
      }
    });
  };

  const createProjectChart = (validData) => {
    const ctx = chartRefs.project.current.getContext('2d');

    const groupedData = validData.reduce((acc, item) => {
      const projectName = item.project_id.name;
      if (!acc[projectName]) {
        acc[projectName] = { total: 0, apis: {} };
      }
      acc[projectName].total += item.credit_consumed;
      
      if (!acc[projectName].apis[item.api_type]) {
        acc[projectName].apis[item.api_type] = 0;
      }
      acc[projectName].apis[item.api_type] += item.credit_consumed;
      
      return acc;
    }, {});

    chartInstances.current.project = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: Object.keys(groupedData),
        datasets: [{
          data: Object.values(groupedData).map(d => d.total),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 99, 71, 0.6)',   // Additional colors
            'rgba(0, 255, 255, 0.6)',    // Additional colors
            'rgba(60, 179, 113, 0.6)',  // Additional colors
            'rgba(255, 165, 0, 0.6)',   
          ]
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const projectName = data.labels[tooltipItem.index];
              const info = groupedData[projectName];
              const percentage = ((info.total / Object.values(groupedData).reduce((sum, v) => sum + v.total, 0)) * 100).toFixed(1);
              
              const lines = [
                `${projectName}: ${info.total} credits (${percentage}%)`
              ];
              
              Object.entries(info.apis).forEach(([api, credits]) => {
                lines.push(`${api}: ${credits} credits`);
              });
              
              return lines;
            }
          }
        }
      }
    });
  };

  const createDistributionChart = (validData) => {
    const ctx = chartRefs.distribution.current.getContext('2d');
  
    const apiCounts = validData.reduce((acc, item) => {
      if (!acc[item.api_type]) acc[item.api_type] = 0;
      acc[item.api_type]++;
      return acc;
    }, {});
  
    const colorPalette = [
      'rgba(255, 99, 132, 0.6)',
      'rgba(54, 162, 235, 0.6)',
      'rgba(255, 206, 86, 0.6)',
      'rgba(75, 192, 192, 0.6)',
      'rgba(153, 102, 255, 0.6)',
      'rgba(255, 159, 64, 0.6)',
      'rgba(255, 99, 71, 0.6)',   // Additional colors
      'rgba(0, 255, 255, 0.6)',    // Additional colors
      'rgba(60, 179, 113, 0.6)',  // Additional colors
      'rgba(255, 165, 0, 0.6)',   // Additional colors
    ];
  
    chartInstances.current.distribution = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: Object.keys(apiCounts),
        datasets: [{
          data: Object.values(apiCounts),
          backgroundColor: colorPalette.slice(0, Object.keys(apiCounts).length), // Limit to the number of API types
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const apiType = data.labels[tooltipItem.index];
              const count = apiCounts[apiType];
              const percentage = ((count / Object.values(apiCounts).reduce((a, b) => a + b, 0)) * 100).toFixed(1);
              return `${apiType}: ${count} calls (${percentage}%)`;
            }
          }
        }
      }
    });
  };
  
  const createTimelineChart = (validData) => {
    const ctx = chartRefs.timeline.current.getContext('2d');

    const timelineData = validData.reduce((acc, item) => {
      const date = new Date(item.createdAt).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = { credits: 0, calls: 0 };
      }
      acc[date].credits += item.credit_consumed;
      acc[date].calls += 1;
      return acc;
    }, {});

    // Sort dates
    const sortedDates = Object.keys(timelineData).sort((a, b) => new Date(a) - new Date(b));

    chartInstances.current.timeline = new Chart(ctx, {
      type: 'line',
      data: {
        labels: sortedDates,
        datasets: [
          {
            label: 'Credits',
            data: sortedDates.map(date => timelineData[date].credits),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.1)',
            yAxisID: 'credits'
          },
          {
            label: 'API Calls',
            data: sortedDates.map(date => timelineData[date].calls),
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            yAxisID: 'calls'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const date = data.labels[tooltipItem.index];
              const dataset = data.datasets[tooltipItem.datasetIndex];
              return `${dataset.label}: ${tooltipItem.value}`;
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: 'credits',
              position: 'left',
              ticks: { beginAtZero: true },
              scaleLabel: { display: true, labelString: 'Credits' }
            },
            {
              id: 'calls',
              position: 'right',
              ticks: { beginAtZero: true },
              scaleLabel: { display: true, labelString: 'Number of Calls' }
            }
          ]
        }
      }
    });
  };

  if (!isValidData(data)) {
    return (
      <Alert color="warning">
        No valid data available for visualization
      </Alert>
    );
  }

  return (
    <div>
      <Row>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Credits by API Type</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.apiType} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Credits by Project</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.project} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">API Usage Distribution</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.distribution} />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Usage Timeline</CardTitle>
            </CardHeader>
            <CardBody>
              <div style={{ height: '300px' }}>
                <canvas ref={chartRefs.timeline} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GenAIAnalyticsDashboard;