import axios from "axios";

const getWidgets = async (formdetails, page, records) => {
    const formdata = {
        filters: formdetails,
        page: page,
        records: records
    }
    console.log(formdetails)
    const response = await axios.post('/api/cricket-widget/list', formdata);
    return response.data;
}


const getDetails = async (id) => {
    console.log(id)
    const response = await axios.get(`/api/cricket-widget/details/${id}`);
    return response.data;
}

const deleteWidget = async (id) => {
    console.log(id)
    const response = await axios.post("/api/cricket-widget/status" , id);
    return response.data;
}



const createWidget = async (formdata) => {

    console.log("This is in service", formdata);
    const details = {
        widget_name: formdata.name,
        widget_slug: formdata.widget_slug,
        client_name: formdata.publisher,
        client_slug: formdata.publisher_slug,
        template: formdata.template,
        tournaments: formdata.tournaments,
        matches: formdata.matches,
        layout: formdata.layout,
        data: formdata.widget_data,
        widget_code: formdata.widget_code,
        _id: formdata.id,
        title: formdata.title,
        domain: formdata.domains,
        custom_css: formdata.custom_css,
        association: formdata.association,
        tournament_type: formdata.tournament_type
    }
    console.log("In service:- ", details);
    const response = await axios.post('/api/cricket-widget/create', details);
    return response.data;
}

const getTournaments = async () => {
    const response = await axios.get('/api/cricket-widget/get-tournaments');
    console.log("This is tournaments in service:- ", response);
    return response.data;
}

const getMatches = async (tournamentSlug) => {
    const details = {
        tournament_slug: tournamentSlug,
    }
    const response = await axios.post('/api/cricket-widget/get-matches', details);
    return response.data;
}

const getFeaturedMatches = async () => {
    const response = await axios.get('/api/cricket-widget/get-featured-matches');
    return response.data;
}

const getAllClientNames = async () => {
    const response = await axios.get('/api/cricket-widget/get-client-names');
    return response.data;
}

const getConfigSchema = async () => {
    const response = await axios.get('/api/cricket-widget/get-config-schema');
    console.log("This is the response from the schema:- ", response.data);
    return response.data;
}

const getAssociations = async () => {
    const response = await axios.get('/api/cricket-widget/get-associations');
    return response.data;
}

const getAssociationTournaments = async (associationKey) => {
    const details = {
        association_key: associationKey,
    }

    console.log("In Service:- ", details);
    const response = await axios.post('/api/cricket-widget/get-association-tournaments', details);
    return response.data;
}

export default {
    getWidgets,
    createWidget,
    getTournaments,
    getMatches,
    getDetails,
    deleteWidget,
    getFeaturedMatches,
    getAllClientNames,
    getConfigSchema,
    getAssociations,
    getAssociationTournaments
}