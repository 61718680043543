import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  ModalBody,
  Modal,
  Form,
  CardFooter,
  CardTitle,
  Input,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactBSAlert from "react-bootstrap-sweetalert";
import transactionsService from "../../services/api/transaction-membership-service";
import projectsService from "../../services/api/projects-service";
import projectUsersService from "../../services/api/project-users-service";
import UserContext from "../../UserContext";
import Projects from "../projects/Projects";

const RECORDS_PER_PAGE = 10;

const TRANSACTION_STATUS_OPTIONS = [
  { value: 0, label: "Initiated" },
  { value: 1, label: "Order Created" },
  { value: -1, label: "Order Creation Failed" },
  { value: 2, label: "Completed" },
  { value: -2, label: "Failed" },
];

const TYPE_OPTIONS = [
  { value: "1", label: "One time" },
  { value: "2", label: "Recurring" },
];

const Transactions = () => {
  const location = useLocation();
  const context = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [projectDetails, setProjectDetails] = useState({});
  const [userNameArray, setUserNames] = useState([]);
  const [alert, setAlert] = useState(null);
  const [formState, setFormState] = useState({
    user_id: "",
    transaction_id: "",
    from_date: "",
    to_date: "",
    project_id: new URLSearchParams(location.search).get("projectid"),
    status: [2],
    verified_by: "",
    type: "",
  });

  const projectId = useMemo(
    () => new URLSearchParams(location.search).get("projectid"),
    [location.search]
  );

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await transactionsService.getTransactions(
        formState,
        activePage,
        RECORDS_PER_PAGE
      );
      if (response.status && response.data) {
        setTransactions(response.data);
        const totalCount = await transactionsService.getTotalTransactions(
          formState
        );
        if (totalCount.status) {
          setTotalTransactions(totalCount.data.count);
        }
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [formState, activePage]);

  const fetchProjectUsers = useCallback(async () => {
    if (!projectId) return;
    try {
      const response = await projectUsersService.fetchUsers({
        project_id: projectId,
        page: 1,
        page_size: "",
      });
      if (response?.status && response?.data?.users) {
        const users = response.data.users.map((user) => ({
          value: user.user_id,
          label: user.email ? `${user.name} (${user.email})` : `${user.name} ${user.phone_number}`,
          email: user.email,
        }));
        setUserNames(users);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [projectId]);

  // Effects
  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    fetchProjectUsers();
  }, [fetchProjectUsers]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (!projectId) return;
      try {
        const result = await projectsService.getProjectDetails(projectId);
        if (result?.status) {
          setProjectDetails(result.data);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };
    fetchProjectDetails();
  }, [projectId]);

  // Event handlers
  const handleFormChange = useCallback((field, value) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleSearch = useCallback(
    (e) => {
      e?.preventDefault();
      setActivePage(1);
      fetchTransactions();
    },
    [fetchTransactions]
  );

  const handleReset = useCallback(() => {
    setFormState({
      user_id: "",
      transaction_id: "",
      from_date: "",
      to_date: "",
      project_id: projectId,
      status: [2],
      verified_by: "",
      type: "",
    });

    setActivePage(1);
  }, [projectId]);

  const handleReconcile = useCallback((transactionId) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Are you sure?"
        onConfirm={() => confirmReconcile(transactionId)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, reconcile it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You want to reconcile this transaction!
      </ReactBSAlert>
    );
  }, []);

  const confirmReconcile = async (transactionId) => {
    try {
      const response = await transactionsService.reconcileTransaction(
        transactionId
      );
      if (!response.status && response.error?.errorCode === 403) {
        context.setIsAuthorized(context);
        setAlert(null);
        return;
      }

      setAlert(
        <ReactBSAlert
          success={response.data}
          danger={!response.data}
          title={response.data ? "Completed" : "Failed"}
          onConfirm={() => setAlert(null)}
          confirmBtnBsStyle="info"
        >
          {response.data
            ? "Reconcilation Completed"
            : "Failed to reconcile, Try again."}
        </ReactBSAlert>
      );

      if (response.data) {
        fetchTransactions();
      }
    } catch (error) {
      console.error("Error reconciling transaction:", error);
    }
  };

  const handleOpenModal = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case -1:
        return "Order could not created";
      case 2:
        return "Success";
      case -2:
        return "Failed";
      default:
        return "";
    }
  };

  const renderTransactionRows = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan="8" className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </td>
        </tr>
      );
    }

    if (!transactions.length) {
      return (
        <tr>
          <td colSpan="8" className="text-center">
            <CardTitle tag="h4">No Records Found</CardTitle>
          </td>
        </tr>
      );
    }

    return transactions.map((transaction, index) => {
      const rowNumber = RECORDS_PER_PAGE * (activePage - 1) + index + 1;
   
      return (
        <tr key={transaction.id} >
          <td className="text-left">{rowNumber}</td>
          <td className="text-left">{transaction?.user_id?.email || transaction?.user_id?.phone_number || "" }</td>
          <td className="text-left">₹{(transaction?.amount || 0) / 100}</td>
          <td className="text-left">
           {(transaction?.subscription_id?.pg_subscription_id) || ""}
          </td>

          <td className="text-left">{transaction.pg_txn_id || ""}</td>
          <td className="text-left">
            <Moment format="DD MMM YYYY hh:mm:ss a">
              {transaction.createdat}
            </Moment>
          </td>
          <td className="text-left">
            {transaction.type === "1" ? "One time" : "Recurring"}
          </td>
          <td className="text-left">
            {transaction.status === 1 ? (
              <>
                Order Created
                <Button onClick={() => handleReconcile(transaction.id)}>
                  Reconcile
                </Button>
              </>
            ) : (
              getStatusText(transaction.status)
            )}
          </td>
          <td>
            <Button
              color="info"
              size="sm"
              onClick={() => handleOpenModal(transaction)}
            >
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  if (!projectId) {
    return <Projects navigate="membership/transaction" />;
  }

  return (
    <div className="content">
      {alert}
      <Container>
        <Card className="card-signup">
          <CardHeader className="bg-white p-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <CardTitle tag="h4" className="mb-0">
                Transactions Dashboard
              </CardTitle>
              <div className="text-muted">
                Project: <strong>{projectDetails.name}</strong>
              </div>
            </div>

            <Form id="transactionfilters" onSubmit={handleSearch}>
              <Row>
                <Col md="3">
                  <Select
                    isClearable
                    placeholder="Select User"
                    options={userNameArray}
                    value={
                      userNameArray.find(
                        (option) => option.value === formState.user_id
                      ) || null
                    }
                    onChange={(option) =>
                      handleFormChange("user_id", option?.value || "")
                    }
                    
                  />
                </Col>

                <Col md="3">
                  <ReactDatetime
                    value={formState.from_date}
                    onChange={(date) => handleFormChange("from_date", date._d)}
                    inputProps={{
                      className: "form-control",
                      placeholder: "From Date",
                    }}
                  
                  />
                </Col>

                <Col md="3">
                  <ReactDatetime
                    value={formState.to_date}
                    onChange={(date) => handleFormChange("to_date", date._d)}
                    inputProps={{
                      className: "form-control",
                      placeholder: "To Date",
                    }}
                  
                  />
                </Col>

                <Col md="3">
                  <Input
                    type="text"
                    placeholder="Transaction Id"
                    value={formState.transaction_id}
                    onChange={(e) =>
                      handleFormChange("transaction_id", e.target.value)
                    }
                   
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md="3">
                <Select
                        isMulti
                        isClearable
                        placeholder="Select Status"
                        options={TRANSACTION_STATUS_OPTIONS}
                        value={TRANSACTION_STATUS_OPTIONS.filter((option) =>
                          formState.status.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormChange(
                            "status",
                            selectedOptions
                              ? selectedOptions.map((opt) => opt.value)
                              : []
                          )
                        }
                       
                      />
                </Col>

                <Col md="3">
                  <Select
                    isClearable
                    placeholder="Transaction Type"
                    options={TYPE_OPTIONS}
                    value={
                      TYPE_OPTIONS.find(
                        (option) => option.value === formState.type
                      ) || null
                    }
                    onChange={(option) =>
                      handleFormChange("type", option?.value)
                    }
                    
                  />
                </Col>
                <Col md="6">
                  <Button className="btn-round mr-2" color="info" type="submit">
                    Search
                  </Button>
                  <Button
                    className="btn-round"
                    color="warning"
                    type="button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>

            <hr />
            {totalTransactions > 0 ? (
              <Row>
                <Col md="6">
                  <div style={{ float: "right", margin: "5px" }}>
                    <b>
                      {" "}
                      {(activePage - 1) * RECORDS_PER_PAGE + 1} -{" "}
                      {Math.min(
                        activePage * RECORDS_PER_PAGE,
                        totalTransactions
                      )}{" "}
                      of {totalTransactions}
                    </b>
                  </div>
                </Col>
                <Col md="6">
                  <div style={{ float: "left" }}>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={RECORDS_PER_PAGE}
                      totalItemsCount={totalTransactions}
                      pageRangeDisplayed={3}
                      onChange={setActivePage}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </Col>
              </Row>
            ) : null}

            <Table responsive>
              <thead className="text-primary">
                <tr>
                  <th className="text-left">#</th>
                  <th className="text-left">User Email or Phone No.</th>
                  <th className="text-left">Transaction Amount(INR)</th>
                  <th className="text-left">Membership ID</th>
                  <th className="text-left">Transaction Id</th>
                  <th className="text-left">Transaction Date</th>
                  <th className="text-left">Type</th>
                  <th className="text-center">Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{renderTransactionRows()}</tbody>
            </Table>
            <Modal
              isOpen={modalOpen}
              toggle={() => setModalOpen(false)}
              size="md"
            >
              <ModalBody className="p-3 position-relative">
                {/* Close Button */}
                <div
                  className="position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    right: "12px",
                    top: "8px",
                    cursor: "pointer",
                    zIndex: 1,
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "25px",
                    height: "25px",
                  }}
                  onClick={() => setModalOpen(false)}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-white" />
                </div>

                {/* Transaction Details Section */}
                <div className="mb-3">
                  <h6 className="mb-2">Transaction Details</h6>
                  <div className="border rounded">
                    <Row className="g-0">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Transaction ID
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Order ID</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.order_id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          PG Transaction ID
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.pg_txn_id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          PG Transaction Message
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.pg_txn_message || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Amount</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          ₹{selectedTransaction?.amount / 100 || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Status</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {getStatusText(selectedTransaction?.status)}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Transaction Date
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          <Moment format="DD MMM YYYY hh:mm:ss a">
                            {selectedTransaction?.createdat}
                          </Moment>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div>
                  <h6 className="mb-2">Membership Details</h6>
                  <div className="border rounded">
                    <Row className="g-0">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Membership ID
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id
                            ?.pg_subscription_id || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Paid Count</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.paid_count ||
                            ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Status</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.status || ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Type</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.total_count ===
                          1
                            ? "One Time"
                            : selectedTransaction?.subscription_id?.total_count
                            ? "Recurring"
                            : ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">Share Url</div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.share_url ||
                            ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Membership Start Date
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.start_at
                            ? moment
                                .unix(
                                  selectedTransaction?.subscription_id?.start_at
                                )
                                .format("DD MMM YYYY")
                            : ""}
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-0 border-top">
                      <Col xs="5" className="p-2 bg-light">
                        <div className="fw-semibold text-dark">
                          Membership End Date
                        </div>
                      </Col>
                      <Col className="p-2">
                        <div className="fw-semibold">
                          {selectedTransaction?.subscription_id?.end_at
                            ? moment
                                .unix(
                                  selectedTransaction?.subscription_id?.end_at
                                )
                                .format("DD MMM YYYY")
                            : ""}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </CardHeader>
          <CardFooter>
            <hr />
          </CardFooter>
        </Card>
      </Container>
    </div>
  );
};

export default Transactions;
