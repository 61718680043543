import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import subscriptionsService from "../../services/api/subscription-membershipl-service.js";
import transactionsService from "../../services/api/transaction-membership-service";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import projectUsersService from "../../services/api/project-users-service";
import UserSubscriptions from "./UserSubscription.js";
import UserTransaction from "./UserTransaction.js";

const UserProfileDetailedView = () => {
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("projectid");

  const [user, setUser] = useState(null); // State to store the user object
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [activePage, setActivePage] = useState(1);
  const [records] = useState(10);
  const [totalSubscriptions, setTotalSubscriptions] = useState(5);
  const [totalTransactions, setTotalTransactions] = useState(5);
  const [renderComponent, setRenderComponent] = useState(true);

  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const userId = new URLSearchParams(window.location.search).get("userid");

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const filterDetails = {
        user_id: userId,
        project_id: projectId,
      };

      try {
        let subscriptionsData = await subscriptionsService.getSubscriptions(
          filterDetails,
          activePage,
          records
        );

        if (subscriptionsData.status && subscriptionsData.data) {
          setSubscriptions(subscriptionsData.data.subscriptions);
          setTotalSubscriptions(subscriptionsData.data.count);
        }
        setRenderComponent(true);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setRenderComponent(true);
      }
    };

    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const filters = {
      user_id: userId,
      project_id: projectId,
    };
    const loadTransactions = async () => {
      const response = await transactionsService.getTransactions(filters);
      if (response.status && response.data) {
        setTransactions(response.data);
        setRenderComponent(true);
      }
    };
    const loadTotalTransactions = async () => {
      const response = await transactionsService.getTotalTransactions(filters);
      if (response.status) {
        setTotalTransactions(response.data.count);
      }
    };

    loadTransactions();
    loadTotalTransactions();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const result = await projectUsersService.fetchUsers({
          project_id: projectId,
          user_id : userId
        });

        if (result && result.status === false) {
          setError("No users found");
        } else {
         
          console.log(result.data)
          const matchedUser = result.data.users.find(
            (user) => user.user_id === userId
          );

          if (matchedUser) {
            setUser(matchedUser);
          } else {
            setError("User not found");
          }
        }
      } catch (err) {
        console.error("Error fetching users:", err);
        setError("Error fetching users");
      } finally {
        setIsLoading(false);
      }
    };

    console.log("users", user);
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]); // Re-run if userId changes

  if (!user) {
    return <div>No user found!</div>;
  }

  return (
    <div className="content mt-10">
      <Container fluid className="py-4">
        <Row className="align-items-stretch">
          {/* User Profile Card */}
          <Col className="ml-auto mr-auto d-flex flex-column" md="3">
            <Card className="card-signup text-center dp-box flex-grow-1">
              <CardBody>
                <div className="mb-3">
                  <img
                    src={
                      user.picture ||
                      "https://t3.ftcdn.net/jpg/04/85/67/08/360_F_485670810_kCPqkWudAgcVpt8vIRiH95tBrxT33RwN.jpg"
                    }
                    alt={user.name || "User"}
                    className="rounded-circle me-3 mb-2"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src =
                        "https://t3.ftcdn.net/jpg/04/85/67/08/360_F_485670810_kCPqkWudAgcVpt8vIRiH95tBrxT33RwN.jpg"; // Fallback image
                    }}
                  />
                  <div>
                    <h5 className="text-xl font-bold mb-2">{user.name}</h5>
                    <p className="text-sm text-gray-500 mb-0">{user.email}</p>
                    <p className="mb-0 text-base text-gray-500">
                      {user.phone_number || ""}
                    </p>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{
                    flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex flex-column align-items-center gap-2">
                    <div
                      className={`rounded-circle ${
                        !transactions || transactions.length === 0
                          ? "bg-danger"
                          : "bg-success"
                      }`}
                      style={{ width: "15px", height: "15px" }}
                    ></div>
                    <small className="text-muted">Status</small>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold fs-6">
                      {user.createdAt
                        ? new Date(user.createdAt).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        : "-"}
                    </div>
                    <small className="text-muted">Created</small>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold fs-6">
                      {totalSubscriptions || "0"}
                    </div>
                    <small className="text-muted">Membership</small>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="ml-auto mr-auto d-flex flex-column" md="9">
            <UserSubscriptions userId={userId} projectId={projectId} />
          </Col>
        </Row>
        <UserTransaction userId={userId} projectId={projectId} />
      </Container>
    </div>
  );
};

export default UserProfileDetailedView;
