import React, { useEffect, useState, useContext } from "react";
import Select from 'react-select'; 
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import jobsService from "../../services/api/jobs-service";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Alert,
    Spinner,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    Container,
    Row,
    Col,
  } from "reactstrap";
import UserContext from "../../UserContext";


const EditScheduledJob = (props)=>{

    const context = useContext(UserContext);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
        type:""
    });

    // const [jobId, setJobId] = useState("");

    const [formData, setFormData] = useState({
        jobData: "",
        jobName: "",
        scheduleTime:"",
        endDate:"",
        frequency:"",
        jobId:props.match.params["jobId"]
    })

    // const jobType =[
    //     {value:"", label:"All"},
    //     {value:"instant", label:"Instant"},
    //     {value:"schedule", label:"Schedule"},
    // ]

    useEffect(()=>{
        (async()=>{


            const scheduledJobId = props.match.params["jobId"];

            if(scheduledJobId){
                const response = await jobsService.getJobData(scheduledJobId);
                const responseData = response.data;

                console.log(responseData)
                setFormData({
                    jobId: scheduledJobId,
                    jobData: responseData.job_data,
                    jobName: responseData.job_name,
                    frequency: responseData.frequency,
                    scheduleTime: responseData.scheduled_time,
                    endDate: responseData.end_date
                })
            }
        })();
    },[])
    

    const jobNameOptions =[
        {value:"dailyReports", label:"Daily report"},
        {value:"contractManagement", label:"Contract  Management"},
        {value:"updateContractQuota" , label: "Update Contract Quota"},
        {value:"trackStats", label:"Track Stats"},
        {value : "contractExpiryAlert" , label:"Contract Expiry Alert"},
        {value : "bigqueryDailyReportSync" , label:"Bigquery Daily Report Sync"},
        {value: "validateCname" , label: "Validate Cname"},
        {value: "trendingArticle", label: "Process Trending Articles"},
        {value: "autonotificationDailyReport", label: "Auto-notifications Daily Report"},
        {value: "cflogHealthCheck", label: "Trending Article CFLog HealthCheck"},
        {value: "briefNotification", label: "Brief Notification"},
        {value: "ctvVideoImport", label: "CTV Video Import"},
    ]

    
    const handleSubmit = async(event)=>{
        event.preventDefault();

        console.log(formData)
        const response = await jobsService.editJob(formData);

        console.log(response)
        if(!response.status && response.error.errorCode === 403){
            setAlert({show:false});
            context.setIsAuthorized(context);
        }
        else if(response.data.isModified){
            setAlert({
                show: true,
                type:"success",
                message:"Job Scheduled successfully"
            })
        }else{
            setAlert({
                show: true,
                type:"danger",
                message:"Unable to Schedule job"
            })
        }

    }

    const handleChange = async(event)=>{
        setFormData({
            ...formData,
            [event.target.name]:event.target.value
        })
    }

    const handleScheduleTimeChange = async(event)=>{
        setFormData({
            ...formData,
            scheduleTime: moment(event._d).utc().toString()
        })
    }

    const handleEndTimeChange = async(event)=>{
        setFormData({
            ...formData,
            endDate: moment(event._d).utc().toString()
        })
    }

    const handleJobNameChange = (event)=>{
        setFormData({
            ...formData,
            jobName:event.value
        })
    }

    const disablePastDates = (current) =>{
        const previousDate =  moment().subtract(1, 'day');
        return current.isAfter(previousDate);
    }

    return (
        <>
         <div className="content">
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Label sm="2">Name <span class="text-danger"> * </span></Label>
                        <FormGroup>
                        <Col sm="7">
                        <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Select Job Name .."
                                name="jobName"
                                id="jobName"
                                options={jobNameOptions}
                                onChange={handleJobNameChange}
                                value={
                                    jobNameOptions.filter(option => option.value === formData.jobName)
                                }
                            />
                        </Col>
                        </FormGroup>
                    </Row>
                    
                    <Row>
                        <Label sm="2">Job Data</Label>
                        <FormGroup>
                        <Col sm="7">
                            <Input
                                name="jobData"
                                type="textarea"
                                onChange={handleChange}
                                value={formData.jobData}
                            />
                        </Col>
                        </FormGroup>
                    </Row>
                   
                    
                        <Row>
                        <Label sm="2">Schedule Time <span class="text-danger"> * </span></Label>
                        <FormGroup>
                        <Col sm="7">
                            <ReactDatetime
                                
                                name="scheduleTime"
                                id="scheduleTime"
                                isValidDate={disablePastDates}
                                value={new Date(formData.scheduleTime)}
                                onChange={handleScheduleTimeChange}
                                dateFormat ={"DD/MM/YYYY"}
                                closeOnSelect
                            />
                        </Col>
                        </FormGroup>
                    </Row>

                    <Row>
                        <Label sm="2">End Date <span class="text-danger"> * </span></Label>
                        <FormGroup>
                        <Col sm="7">
                            <ReactDatetime
                                
                                name="endDate"
                                id="endDate"
                                value={new Date(formData.endDate)}
                                isValidDate={disablePastDates}
                                onChange={handleEndTimeChange}
                                autoComplete = 'off'
                                dateFormat ={"DD/MM/YYYY"}
                                closeOnSelect
                              />
                        </Col>
                        </FormGroup>
                    </Row>

                    <Row>
                        <Label sm="2">Frequency (in minutes) <span class="text-danger"> * </span></Label>
                        <FormGroup>
                        <Col sm="7">
                            <input
                                
                                name="frequency"
                                id="frequency"
                                type="number"
                                min='0'
                                value={formData.frequency}
                                onChange={handleChange}
                            />
                        </Col>
                        </FormGroup>
                    </Row>
                        
                    

                    <Button type="submit">Schedule Job</Button>
                </Form>

                {
                    alert.show? <Alert color={alert.type}>
                        {alert.message}
                    </Alert> :''
                }
            </Container>
            </div>
        </>
    )
}

export default EditScheduledJob;