import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import transactionsService from "../../services/api/transaction-membership-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
} from "reactstrap";

import subscriptionsService from "../../services/api/subscription-membershipl-service";
import Projects from "../projects/Projects";

const RECORDS_PER_PAGE = 3;

const UserSubscriptions = ({ userId, projectId }) => {
  // State for subscriptions
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [subscriptionPage, setSubscriptionPage] = useState(1);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  
  // State for transactions
  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactionPage, setTransactionPage] = useState(1);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Alert state
  const [alert, setAlert] = useState(null);

  // Form state
  const formState = useMemo(() => ({
    user_id: userId,
    project_id: projectId
  }), [userId, projectId]);

  // Transaction filter
  const transactionFilter = useMemo(() => ({
    project_id: projectId,
    subscription_id: selectedSubscriptionId
  }), [projectId, selectedSubscriptionId]);

  // Create alert function
  const createAlert = useCallback((type, message) => {
    const alertProps = {
      style: { display: "block", marginTop: "-100px" },
      onConfirm: () => setAlert(null),
      onCancel: () => setAlert(null),
    };

    switch (type) {
      case "loading":
        return (
          <ReactBSAlert
            info
            {...alertProps}
            showConfirm={false}
            title="Processing..."
          >
            <div className="d-flex flex-column align-items-center">
              <Spinner className="mb-3" />
              <p>{message}</p>
            </div>
          </ReactBSAlert>
        );
      case "success":
        return (
          <ReactBSAlert
            success
            {...alertProps}
            title="Success"
            confirmBtnBsStyle="success"
          >
            {message}
          </ReactBSAlert>
        );
      case "error":
        return (
          <ReactBSAlert
            danger
            {...alertProps}
            title="Error"
            confirmBtnBsStyle="danger"
          >
            {message}
          </ReactBSAlert>
        );
      default:
        return null;
    }
  }, []);

  // Fetch subscriptions
  const fetchSubscriptions = useCallback(async () => {
    if (!projectId || !userId) return;

    setIsSubscriptionLoading(true);
    try {
      const response = await subscriptionsService.getSubscriptions(
        formState,
        subscriptionPage,
        RECORDS_PER_PAGE
      );
      if (response.status && response.data) {
        setSubscriptions(response.data.subscriptions);
        setTotalSubscriptions(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching Memberships:", error);
      setAlert(createAlert("error", "Failed to fetch Memberships"));
    } finally {
      setIsSubscriptionLoading(false);
    }
  }, [projectId, userId, formState, subscriptionPage, createAlert]);

  // Fetch transactions
  const fetchTransactions = useCallback(async () => {
    if (!selectedSubscriptionId) {
      setTransactions([]);
      setTotalTransactions(0);
      return;
    }
    
    setIsTransactionLoading(true);
    try {
      const response = await transactionsService.getTransactions(
        transactionFilter,
        transactionPage,
        RECORDS_PER_PAGE
      );
      if (response.status && response.data) {
        setTransactions(response.data);
        
        const totalCount = await transactionsService.getTotalTransactions(
          transactionFilter
        );
        if (totalCount.status) {
          setTotalTransactions(totalCount.data.count);
        }
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setAlert(createAlert("error", "Failed to fetch transactions"));
    } finally {
      setIsTransactionLoading(false);
    }
  }, [transactionFilter, transactionPage, selectedSubscriptionId, createAlert]);

  // Handle subscription click
  const handleSubscriptionClick = useCallback((subscriptionId) => {
    setSelectedSubscriptionId(subscriptionId);
    setTransactionPage(1);
    setIsModalOpen(true);
  }, []);

  // Get status text
  const getStatusText = useCallback((status) => {
    switch (status) {
      case 0:
        return "Initiated";
      case 1:
        return "Order Created";
      case -1:
        return "Order could not be created";
      case 2:
        return "Success";
      case -2:
        return "Failed";
      default:
        return "Unknown";
    }
  }, []);

  // Effect for fetching subscriptions
  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  // Effect for fetching transactions when modal is opened
  useEffect(() => {
    if (isModalOpen && selectedSubscriptionId) {
      fetchTransactions();
    }
  }, [fetchTransactions, isModalOpen, selectedSubscriptionId]);

  if (!projectId) {
    return <Projects navigate="membership/subscription" />;
  }

  return (
    <div className="content">
      {alert}
      <Container>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <CardTitle tag="h4">Membership Dashboard</CardTitle>
              
                  </div>
                {totalSubscriptions > 0 && (
                  <Row>
                    <Col md="6">
                      <div style={{ float: "right", margin: "5px" }}>
                        <b>
                          {(subscriptionPage - 1) * RECORDS_PER_PAGE + 1} -{" "}
                          {Math.min(
                            subscriptionPage * RECORDS_PER_PAGE,
                            totalSubscriptions
                          )}{" "}
                          of {totalSubscriptions}
                        </b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{ float: "left" }}>
                        <Pagination
                          activePage={subscriptionPage}
                          itemsCountPerPage={RECORDS_PER_PAGE}
                          totalItemsCount={totalSubscriptions}
                          pageRangeDisplayed={3}
                          onChange={setSubscriptionPage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                 
              </CardHeader>

              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>#</th>
                      <th>User Email or Phone No.</th>
                      <th>Membership ID</th>
                      <th>Paid Count</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isSubscriptionLoading ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    ) : subscriptions.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <CardTitle tag="h4">No Records Found</CardTitle>
                        </td>
                      </tr>
                    ) : (
                      subscriptions.map((subscription, index) => (
                        <tr key={subscription._id}>
                          <td>
                            {(subscriptionPage - 1) * RECORDS_PER_PAGE + index + 1}
                          </td>
                          <td>{subscription.user_id?.email || subscription?.user_id?.phone_number || ""}</td>
                          <td
                            style={{ cursor: "pointer", color: "#0077b6" }}
                            onClick={() => handleSubscriptionClick(subscription._id)}
                          >
                            {subscription.pg_subscription_id || ""}
                          </td>
                          <td>{subscription.paid_count}</td>
                          <td>
                            {subscription?.total_count === 1
                              ? "One Time"
                              : subscription?.total_count
                              ? "Recurring"
                              : ""}
                          </td>
                          <td>{subscription.status}</td>
                          <td>
                            {subscription?.start_at
                              ? moment
                                  .unix(subscription?.start_at)
                                  .format("DD MMM YYYY")
                              : ""}
                          </td>
                          <td>
                            {subscription?.end_at
                              ? moment
                                  .unix(subscription?.end_at)
                                  .format("DD MMM YYYY")
                              : ""}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </CardBody>
              
              {/* Transactions Modal */}
              <Modal
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                size="lg"
              >
                <ModalBody className="p-3 position-relative">
                  {/* Close Button */}
                  <div
                    className="position-absolute d-flex align-items-center justify-content-center"
                    style={{
                      right: "12px",
                      top: "8px",
                      cursor: "pointer",
                      zIndex: 1,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={() => setIsModalOpen(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} className="text-white" />
                  </div>

                  {/* Transaction Details Section */}
                  <div className="mb-3">
                    <h5 className="mb-3">Transaction Details</h5>

                    <div className="border rounded">
                      {isTransactionLoading ? (
                        <div className="text-center p-4">
                          <Spinner />
                        </div>
                      ) : transactions.length > 0 ? (
                        <>
                          <Table responsive >
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left">#</th>
                                <th className="text-left">User Name</th>
                                <th className="text-left">
                                  Transaction Amount(INR)
                                </th>
                                <th className="text-left">
                                  Effective Amount(INR)
                                </th>
                                <th className="text-left">Transaction Id</th>
                                <th className="text-left">Transaction Date</th>
                                <th className="text-left">Type</th>
                                <th className="text-center">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((transaction, index) => {
                                const rowNumber =
                                  RECORDS_PER_PAGE * (transactionPage - 1) + index + 1;
                             
                                return (
                                  <tr key={transaction.id} >
                                    <td className="text-left">{rowNumber}</td>
                                    <td className="text-left">
                                      {transaction?.user_id?.name || ""}
                                    </td>
                                    <td className="text-left">
                                      ₹{((transaction?.amount || 0) / 100).toFixed(2)}
                                    </td>
                                    <td className="text-left">
                                      ₹
                                      {(
                                        (transaction?.amount || 0) -
                                        (transaction?.coupon_discount || 0)
                                      ) / 100}
                                    </td>
                                    <td className="text-left">
                                      {transaction.pg_txn_id || ""}
                                    </td>
                                    <td className="text-left">
                                      <Moment format="DD MMM YYYY hh:mm:ss a">
                                        {transaction.createdat}
                                      </Moment>
                                    </td>
                                    <td className="text-left">
                                      {transaction.type === "1"
                                        ? "One time"
                                        : "Recurring"}
                                    </td>
                                    <td className="text-center">
                                      {getStatusText(transaction.status)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <div className="text-center p-4">
                          <p>No transactions found for this subscription.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserSubscriptions;