import React, { useEffect, useState, useContext } from "react";
import Pagination from "react-js-pagination";
import Moment from 'react-moment';
import jobsService from "../../services/api/jobs-service";
import ReactDatetime from "react-datetime";
import UserContext from "../../UserContext";
import './jobs.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";

const JobsDashboard = (props)=> {
  const context = useContext(UserContext);
  const [formdetails, setFormDetails] = useState({startDate:'',endDate:'',jobName:'',jobStatus:'',jobFrequency:''});
  const [jobs, setJobs] = useState(false);
  const [totalJobs,setTotalJobs] = useState(0);
  const [activePage,setActivePage] = useState(1);
  const [renderComponent,setRenderComponent] = useState(false);
  const [search,setSearchDetails] = useState({startDate:'',endDate:'',jobName:'',jobStatus:'',jobFrequency:''});
  const records = 10;
  const [status,setStatus] = useState(false);
  const [jobName, setJobName] = useState("")
  const [frequency,setFrequency] = useState(false);
  const [toDate,setToDate] = useState("");
  const [fromDate,setFromDate] = useState("");

  let jobStatusArray = [
    { value: "", label: "All" },
    { value: "0", label: "Pending" },
    { value: "1", label: "Completed" },
    { value: "-1", label: "Cancelled" },
  ];

  let jobFrequencyArray = [
    { value: "", label: "All" },
    { value: "60", label: "Hourly" },
    { value: "1440", label: "Daily" },
    { value: "10080", label: "Weekly" },
    { value: "43200", label: "Monthly" },
    { value: null, label: "NA" },
  ];

  let jobTypeOptions = [
    {value: "", label: "All"},
    {value:"dailyReports", label:"Daily report"},
    {value:"contractManagement", label:"Contract  Management"},
    {value:"updateContractQuota" , label: "Update Contract Quota"},
    {value:"trackStats", label:"Track Stats"},
    {value : "contractExpiryAlert" , label:"Contract Expiry Alert"},
    {value : "bigqueryDailyReportSync" , label:"Bigquery Daily Report Sync"},
    {value: "validateCname" , label: "Validate Cname"},
    {value: "trendingArticle", label: "Process Trending Articles"},
    {value: "ctvVideoImport", label: "CTV Video Import"},
    {value: "cflogHealthCheck", label: "Trending Article CFLog HealthCheck"},
    {value: "briefNotification", label: "Brief Notification"},
    {value: "scheduleNotification", label: "Scheduled Notification"},
    {value: "autonotificationDailyReport", label: "Auto-notifications Daily Report"},
  ]
  
  useEffect( () => {

    (async () =>{
     
        let jobsData = await jobsService.getJobs(formdetails,activePage,records);
            if(jobsData.status){
                setJobs(jobsData.data.jobsList);
                console.log(jobsData)
                setTotalJobs(jobsData.data.count);
            }
            setRenderComponent(true);  
        })();
    }, [activePage,search]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }

    const changeStatus = (event) => {
      setFormDetails({
        ...formdetails,
        jobStatus: event.value,
      });
      setStatus(event)
    }

    const changeFrequency = (event) => {
      setFormDetails({
        ...formdetails,
        jobFrequency: event.value,
      });
      setFrequency(event)
    }

  const handleJobNameChange = (event) =>{
      setFormDetails({
        ...formdetails,
        jobName: event.value
      })
      setJobName(event)
  }

  let jobsElements = [];
  let firstRecord = 0;
  let lastRecord  = 0;
  if(!jobs || !jobs.length){
    if(!renderComponent){
      jobsElements.push(
        <td colSpan="11" className="text-center">
            <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
        </td>
      );
    }else{
        jobsElements.push(
        <td colSpan="11" className="text-center">
            <CardTitle tag="h4">No Jobs Found</CardTitle>
        </td>
    );
    }
  }else{
      let i=1;
      jobs.forEach(function(jobDetails) {
          let className = '';
          let trigger_time="";
          if (jobDetails.trigger_time) {
            trigger_time = <Moment format="DD MMM YYYY hh:mm:ss a">{jobDetails.trigger_time}</Moment> 
           }
          if(jobDetails.status==-1){
            className = 'table-danger';
          }
          if(i==1){
            firstRecord = (activePage-1)*records+i;
          }

          let sjobStatus = '';
          jobStatusArray.forEach(element => {
              if(jobDetails.status==element.value){
                sjobStatus = element.label;
              }
          });

          let sjobFrequency = '';
          jobFrequencyArray.forEach(felement => {
              if(jobDetails.frequency==felement.value){
                sjobFrequency = felement.label;
              }
          });


          if(!sjobFrequency){
            let tempFrequency = jobDetails.frequency;
            if(tempFrequency > 1440*7){
                sjobFrequency = parseInt(tempFrequency/(1440*7))+ " week";
                tempFrequency = tempFrequency % (1440*7);
            }
            if(tempFrequency > 1440){
              sjobFrequency = parseInt(tempFrequency/1440)+ " day";
              tempFrequency = tempFrequency % 1440;
            }

            if(tempFrequency > 60){
              sjobFrequency = parseInt(tempFrequency/60)+ " hour";
              tempFrequency = tempFrequency % (1440*7);
            }

            if(tempFrequency < 60){
              sjobFrequency = tempFrequency+ " minute";
            }
          
          }

          let actionButtons = <button className="btn btn-primary" onClick={() =>{window.location.href = "/jobs/reschedule/"+jobDetails._id}}>Reschedule</button>;

          if(jobDetails.status === 0){
            actionButtons = <>
              <button className="btn btn-warning" onClick= {() =>{cancelScheduledJob(jobDetails._id)}}>Cancel</button>
              <button className="btn btn-primary" onClick={() =>{window.location.href = "/jobs/edit-job/"+jobDetails._id}}>Edit</button>
            </>
          }

          jobsElements.push(
            <tr id={"row_"+jobDetails.id} className={className}>
              <td className="text-left">{(activePage-1)*10+i}</td>
               <td className="text-left">
                {jobDetails.job_name}
               </td>
               <td className="text-left">
                   {jobDetails.job_data}
               </td>
               <td className="text-left">
                   <Moment format="DD MMM YYYY hh:mm:ss a">{jobDetails.scheduled_time}</Moment>
               </td>
               <td className="text-left">
                    {trigger_time}
               </td>
               <td className="text-left">
                   {sjobFrequency}
               </td>
               <td className="text-left">
                    <Moment format="DD MMM YYYY hh:mm:ss a">{jobDetails.end_date}</Moment>
               </td>
               <td>
                  {jobDetails.last_modified_by}
               </td>
               <td>
                  <Moment format="DD MMM YYYY hh:mm:ss a">{jobDetails.updatedAt}</Moment>
               </td>
               <td className="text-left">
                   {sjobStatus}
               </td>

               <td className="text-left">
                  {actionButtons}
               </td>

            </tr>
        )
        if(i==jobs.length){
          lastRecord = (activePage-1)*records+i;
        }
        i++;
        if(i==jobs.length){
          lastRecord = (activePage-1)*records+i;
        }
    })
  }

  
  const handleFromDateChange = (event) => {
    console.log("Event:",event.value);
    setFormDetails({
      ...formdetails,
      startDate: document.getElementById('fromDate').value,
    });
    setFromDate(document.getElementById('fromDate').value);
  }

  const handleToDateChange = () => {
    setFormDetails({
      ...formdetails,
      endDate: document.getElementById('toDate').value,
    });
    setToDate(document.getElementById('toDate').value);
  }
  const handleDateChange = (event,key) => {
      setFormDetails({
        ...formdetails,
        [key]: event._d,
      });
  }
console.log(formdetails)
  const handleSubmit = function(e){  searchJobs();e.preventDefault() }

  const searchJobs = () => {
      setActivePage(1);
      setSearchDetails(formdetails);
  }

  const cancelScheduledJob = async(jobId) =>{
    const jobCancelled = await jobsService.cancelJob(jobId);
    if(!jobCancelled.status && jobCancelled.error.errorCode === 403){
      context.setIsAuthorized(context);
    }else{
      window.location.reload();
    }
    console.log(jobCancelled);
  }

  const resetElements = () => {
    document.getElementById('status').value ="";
    document.getElementById('frequency').value = "";
    document.getElementById('toDate').value = '';
    document.getElementById('fromDate').value = '';

    setStatus("");
    setFrequency("");
    setToDate("");
    setFromDate("");

    setFormDetails({startDate:'',endDate:'',jobName:'',jobStatus:'',jobFrequency:''});
    setSearchDetails({startDate:'',endDate:'',jobName:'',jobStatus:'',jobFrequency:''});

  }

    return (
      <div className="content">
        <Container>
          <Button onClick={(event)=>  window.location.href = "/jobs/create"} href="javascript:void(0)" class="btn-rotate nav-link" className=" btn btn-primary" >Schedule a Job</Button>
          <Row>
          <Col className="ml-auto mr-auto"  md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                <CardTitle tag="h4">
                   Scheduled Jobs
                </CardTitle>
                <Form id="jobsfilters" action="" className="form" method="POST" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="3">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Select Job Name"
                        name = "status"
                        id="status"
                        options={jobTypeOptions}
                        value={jobName}
                        onChange={handleJobNameChange}
                      />
                    </Col>
                    <Col md="2">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Status .."
                      name = "status"
                      id="status"
                      options={jobStatusArray}
                      value={status}
                      onChange={changeStatus}
                    />
                    </Col>
                    <Col md="3">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Select Frequency .."
                      name = "frequency"
                      id="frequency"
                      options={jobFrequencyArray}
                      value={frequency}
                      onChange={changeFrequency}
                    />
                    </Col>
                    <Col md="2">
                    <ReactDatetime
                        value={formdetails.startDate}
                        onChange={(event)=> handleDateChange(event,"startDate")}
                        inputProps={{
                          className: "form-control",
                          placeholder: "From date",
                          id:"fromDate",
                          input:'false',
                          autoComplete : 'off'
                        }}
                        dateFormat ={"DD/MM/YYYY"}
                        closeOnSelect
                      />
                    </Col>
                    <Col md="2">
                    <ReactDatetime
                        className="toDateFilter"
                        value={formdetails.endDate}
                        onChange={(event)=> handleDateChange(event,"endDate")}
                        inputProps={{
                          className: "form-control",
                          placeholder: "To date",
                          id:"toDate",
                          autoComplete : 'off',
                        }}
                        dateFormat ={"DD/MM/YYYY"}
                        closeOnSelect
                      />
                    </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                      <div style={{'float':'left'}}>
                      <Button
                        className="btn-round"
                        color="info"
                        type="submit"
                      >
                        Search
                      </Button>
                      <Button
                        className="btn-round"
                        color="warning"
                        type="button"
                        onClick={resetElements}
                      >
                        Reset
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <hr></hr>
                {totalJobs > 0 ? 
                  <Row>
                    <Col md="6">
                      <div style={{float:'right'}}>
                        <b>{firstRecord} - {lastRecord} of {totalJobs}</b>
                      </div>
                    </Col>
                    <Col md="6">
                      <div style={{float:'left'}}>
                          <Pagination
                          activePage={activePage}
                          itemsCountPerPage={records}
                          totalItemsCount={totalJobs}
                          pageRangeDisplayed={3}
                          onChange={handlePageChange.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                          />
                      </div>
                    </Col>
                  </Row> 
                : null}
                </CardHeader>
                <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">#</th> 
                        <th className="text-left">Job Name</th>   
                        <th className="text-left">Job Data</th>
                        <th className="text-left">Scheduled At</th>
                        <th className="text-left">Triggered At</th>
                        <th className="text-left">Frequency</th>
                        <th className="text-left">Ending At</th>
                        <th className="text-left">Last Modified By</th>
                        <th className="text-left">Last Modified At</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Actions</th>
                      </tr>
                    </thead>  
                    <tbody>{jobsElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter>

                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
      </div>
    );
  
  
  
}

export default JobsDashboard;
