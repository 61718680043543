import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupText,
  Tooltip,
  FormText,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import Select, { components } from "react-select";

// TemplateFormBuilder component
const TemplateFormBuilder = ({
  template,
  setFormData,
  formData,
  errors,
  setErrors,
  initialData,
}) => {
  console.log(template)
  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const renderFormField = (fieldKey, fieldConfig) => {
    const error = errors[fieldKey];
    console.log("fieldConfig", fieldConfig);
    console.log("fieldKey", fieldKey);
    switch (fieldConfig.type) {
      case "hex_color":
        return (
          <Row>
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              <div className="inptFomColr">
                <div
                  className="clrChng"
                  style={{ display: "inline-block", border: "none" }}
                >
                  <Input
                    type="color"
                    value={formData[fieldKey] || fieldConfig.default}
                    onChange={(e) =>
                      handleInputChange(fieldKey, e.target.value)
                    }
                    className="w-12 h-8 mr-2 rounded cursor-pointer"
                  />
                </div>
                <div className="clrTxt" style={{ display: "inline-block" }}>
                  <input
                    type="text"
                    value={formData[fieldKey] || fieldConfig.default}
                    onChange={(e) =>
                      handleInputChange(fieldKey, e.target.value)
                    }
                    className="w-full p-2 border rounded"
                    pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                    placeholder="e.g. #FFFFFF"
                  />
                </div>
              </div>
              {error && <p className="text-danger text-xs">{error}</p>}
            </Col>
          </Row>
        );
      case "string":
        return (
          <Row>
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              <div className="inptFom1">
                <FormGroup>
                  <Input
                    type="text"
                    value={formData[fieldKey] || fieldConfig.default || ""}
                    onChange={(e) =>
                      handleInputChange(fieldKey, e.target.value)
                    }
                    className="w-full p-2 border rounded"
                    placeholder={`Enter ${fieldConfig.label}`}
                    required={fieldConfig.required}
                  />
                      {error && <p className="text-danger text-xs">{error}</p>}
                </FormGroup>
            
              </div>
           
            </Col>
          </Row>
        );
      case "url":
        return (
          <Row>
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              <div className="inptFom1">
                <FormGroup>
                  <Input
                    type="url"
                    value={formData[fieldKey] || fieldConfig.default || ""}
                    onChange={(e) =>
                      handleInputChange(fieldKey, e.target.value)
                    }
                    className="w-full p-2 border rounded"
                    placeholder="https://example.com/image.png"
                    required={fieldConfig.required}
                  />
                  {error && <p className="text-danger text-xs">{error}</p>}
                </FormGroup>
              </div>
              
            </Col>
          </Row>
        );
      case "single_select":
        return (
          <Row key={fieldKey} className="mb-3">
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                value={
                  fieldConfig.options?.find(
                    (option) => option.value === formData[fieldKey]
                  ) || null
                }
                options={fieldConfig.options || []}
                onChange={(selectedOption) =>
                  handleInputChange(fieldKey, selectedOption?.value)
                }
              />
              {error && <p className="text-danger text-xs">{error}</p>}
            </Col>
          </Row>
        );
      case "multi_select":
        return (
          <Row key={fieldKey} className="mb-3">
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              <Select
                isMulti
                className="react-select primary"
                classNamePrefix="react-select"
                value={
                  fieldConfig.options?.filter((option) =>
                    formData[fieldKey]?.includes(option.value)
                  ) || []
                }
                options={fieldConfig.options || []}
                onChange={
                  (selectedOptions) =>
                    handleInputChange(
                      fieldKey,
                      selectedOptions
                        ? selectedOptions.map((opt) => opt.value)
                        : []
                    ) // Ensure empty array on clear
                }
              />
              {error && <p className="text-danger text-xs">{error}</p>}
            </Col>
          </Row>
        );
      case "boolean":
        return (
          <Row>
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              <div className="inptFom1">
                <FormGroup>
                  <Switch
                    name={fieldKey}
                    offColor="success"
                    offText={<i className="nc-icon nc-simple-remove" />}
                    onColor="success"
                    onText={<i className="nc-icon nc-check-2" />}
                    value={formData[fieldKey] ?? fieldConfig.default ?? false}
                    onChange={(value, enabled) =>
                      handleInputChange(fieldKey, enabled)
                    }
                  />
                  {error && <p className="text-danger text-xs">{error}</p>}
                </FormGroup>
              </div>
            </Col>
          </Row>
        );

      case "radio":
        return (
          <Row key={fieldKey} className="mb-3">
            <Col sm="6">
              <div className="designLabel">
                <small className="text-default">
                  {fieldConfig.label}
                  {fieldConfig.required ? (
                    <span class="text-danger"> * </span>
                  ) : (
                    ""
                  )}
                </small>
              </div>
            </Col>
            <Col sm="6">
              {fieldConfig.options.map((option, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <Input
                    type="radio"
                    name={fieldKey}
                    value={option.value}
                    checked={formData[fieldKey] === option.value}
                    onChange={() => handleInputChange(fieldKey, option.value)}
                  />
                  <span>{option.label}</span>
                 
                </div>
               
              ))}
                {error && <p className="text-danger text-xs">{error}</p>}
            </Col>
          </Row>
        );
      default:
        return (
          <div className="form-group mb-4" key={fieldKey}>
            <label className="block text-sm font-medium mb-1">
              {fieldConfig.label}
            </label>
            <input
              type="text"
              value={formData[fieldKey] || fieldConfig.default || ""}
              onChange={(e) => handleInputChange(fieldKey, e.target.value)}
              className="w-full p-2 border rounded"
              required={fieldConfig.required}
            />
            {fieldConfig.required && (
              <span className="text-xs text-red-500">Required</span>
            )}
          </div>
        );
    }
  };

  if (!template) {
    return (
      <div className="p-4 text-center">
        Please select a template to continue
      </div>
    );
  }

  return (
    <Row style={{ width: "40%", minHeight: "100vh" }}>
      <Col className="ml-auto mr-auto">
        <Card className="content d-flex justify-content-center">
          {
            Object.keys(template).length > 0 ? (
              <div className="template-form-builder px-4">
              <div className="d-flex align-items-center gap-2">
                <CardTitle tag="h4">{template.label} Configuration</CardTitle>
              </div>
  
              {template.helptext && (
                <p className="text-sm text-gray-600 mb-4">{template.helptext}</p>
              )}
  
              <div className="form-fields">
                {template.items &&
                  Object.keys(template.items).map((fieldKey) => {
                    return renderFormField(fieldKey, template.items[fieldKey]);
                  })}
              </div>
  
              <div className="mt-6 flex justify-content-center">
                <Button
                  className="btn-round"
                  color="danger"
                  type="button"
                  onClick={() => {
                    console.log("Initial Data", initialData);
                    setFormData(initialData);
                    setErrors({});
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center text-center w-100" style={{ minHeight: "50vh" }}>
              <CardTitle tag="h4" className="text-muted">No Configuration Available</CardTitle>
              <p className="text-muted">Please select a valid template to view the configuration form.</p>
            </div>
            )
          }
        
        </Card>
      </Col>
    </Row>
  );
};

export default TemplateFormBuilder;
